import type { IWaypointNode, WaypointNode } from '~/models/journey/waypoints'
import { WaypointResult, WaypointType } from '~/generated/api-clients-generated'
import type { Path } from '~/types/util'

export default abstract class WaypointNodeBase implements IWaypointNode {
  abstract type: WaypointType
  protected _destinations: Map<WaypointResult, WaypointNode | Path> = new Map<
    WaypointResult,
    WaypointNode
  >()

  addDestination(result: WaypointResult, dest: WaypointNode | Path) {
    this._destinations.set(result, dest)
  }

  set destinations(ds: Map<WaypointResult, WaypointNode | Path>) {
    this._destinations = ds
  }
}
