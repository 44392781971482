import * as SentryLib from '@sentry/vue'
export class Sentry {
  static use() {
    const identify = (id: string) =>
      SentryLib.setUser({
        id: id
      })

    const captureException = (ex: any) => SentryLib.captureException(ex)
    const captureMessage = (msg: string) => SentryLib.captureMessage(msg)

    return {
      identify,
      captureException,
      captureMessage
    }
  }
}
