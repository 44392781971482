import type { PlanType } from '~/generated/api-clients-generated'

export class ConvoSectionSchema {
  activePrompt?: string | null
  prompts?: ConvoPromptSchema[]
  hidePrompts?: boolean
  context?: any
  initialPromptCount?: number
  showAllPrompts?: boolean
  subsection?: ConvoSectionSchema

  constructor(data: ConvoSectionSchema) {
    this.prompts = data.prompts
    this.hidePrompts = data.hidePrompts
    this.initialPromptCount = data.initialPromptCount ?? 4
    this.context = data.context
  }
}

export class ConvoSchema extends ConvoSectionSchema {
  id: string

  constructor(data: ConvoSchema) {
    super(data)
    this.id = data.id
  }
}

export class ConvoSubsectionSchema extends ConvoSectionSchema {
  id: string

  constructor(data: ConvoSubsectionSchema) {
    super(data)
    this.id = data.id
  }
}

export class ConvoPromptSchema {
  id: string
  planTypes?: PlanType[] | null
  subsection?: ConvoSubsectionSchema

  constructor(data: ConvoPromptSchema) {
    this.id = data.id
    this.planTypes = data.planTypes
    this.subsection = data.subsection
  }
}
