import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { PharmacyCampaigns } from '~/composables/PharmacyCampaigns'

export class JourneyHealthPreProcessNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    await PharmacyCampaigns.run()

    return Promise.resolve(undefined)
  }
}
