import { defineStore } from 'pinia'
import type { LogoContent } from '~/types/cms'

export class CarrierStore {
  static use = defineStore('carriers', () => {
    const logoCatalog = ref<Record<string, string>>({})
    const getLogo = (key: string, planYear: string | number) =>
      logoCatalog.value[getKey(key, planYear)]

    const getKey = (key: string, planYear: string | number) => `${key}-${planYear}`

    async function load() {
      const { getCarrierLogoContent } = Cms.use()

      const logosPromise = getCarrierLogoContent()

      const { logos } = await logosPromise
      logoCatalog.value = logos.value.reduce(
        (acc: Record<string, string>, x: LogoContent) => ({
          ...acc,
          [getKey(x.key, x.planYear)]: x.fileName
        }),
        {} as Record<string, string>
      )
    }

    return {
      logoCatalog,
      getLogo,
      load
    }
  })

  static async load() {
    const store = CarrierStore.use()
    await store.load()
  }
}
