import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { SessionStore } from '~/stores/session'

export class CancelIepEditsNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    const session = SessionStore.use()

    session.editingIepContext = false
    session.modifiedIepContext = {}
  }
}
