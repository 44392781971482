import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { SessionStore } from '~/stores/session'
import { PlanType } from '~/generated/api-clients-generated'

export class ShowDefaultSortNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    const session = SessionStore.use()

    return this.yesNo(
      session.planType === PlanType.MAPD && flag('show-default-sort-screen', false).value
    )
  }
}
