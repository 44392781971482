import { isPageNode } from '~/models/journey/waypoints'
import type { WaypointNode } from "~/models/journey/waypoints";
import type { Catalog } from '~/types/util'
import type { Journey, Waypoint } from '~/generated/api-clients-generated'
import { WaypointType } from "~/generated/api-clients-generated";
import NodeFactory from '~/models/journey/NodeFactory'

export type JourneyVm = {
  id: number | null
  signInPath: string | null
  signOutPath: string | null
  signUpPath: string | null
  accountHomePath: string | null
  catalog: Catalog<WaypointNode>
}

export class JourneyBuilder {
  static empty() {
    return {
      id: null,
      signInPath: null,
      signOutPath: null,
      signUpPath: null,
      accountHomePath: null,
      catalog: {}
    }
  }

  static build(journey: Journey): JourneyVm {
    return {
      id: journey.id ?? null,
      signInPath: journey.signInPath ?? null,
      signOutPath: journey.signOutPath ?? null,
      signUpPath: journey.signUpPath ?? null,
      accountHomePath: journey.accountHomePath ?? null,
      catalog: this.buildCatalog(journey)
    }
  }

  private static buildCatalog(journey: Journey): Catalog<WaypointNode> {
    const catalog: Catalog<WaypointNode> = {}

    console.log('JOURNEY', journey)

    const fromDto: (dto: Waypoint | null) => WaypointNode | null = (dto) => {
      if (_isNil(dto)) return null

      if (dto.path && catalog[dto.path?.toLowerCase()]) return catalog[dto.path?.toLowerCase()]

      const waypoint = createWaypoint(dto)

      for (const result of dto.results!) {
        const next = fetchDto(result.nextWaypointId)
        const destination = next!.type === WaypointType.Page ? next?.path! : fromDto(next)

        if (!_isNil(destination)) {
          waypoint.addDestination(result.result!, destination)
        }
      }

      if (isPageNode(waypoint)) {
        catalog[waypoint.path?.toLowerCase()] = waypoint
      }

      return waypoint
    }

    const fetchDto: (id: number | null | undefined) => Waypoint | null = (id) =>
      !id ? null : byId.get(id) ?? null

    const createWaypoint: (dto: Waypoint) => WaypointNode = (dto) => NodeFactory.create(dto)

    const byId = new Map<number, Waypoint>(journey.waypoints?.map((w) => [w.id!, w] as const))

    const pages = journey.waypoints?.filter((w) => !!w.path) || []

    for (const o of pages) {
      fromDto(o)
    }

    return catalog
  }
}
