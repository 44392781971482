import { defineStore } from 'pinia'
import type { Rx } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'
import { RxService } from '~/composables/RxService'

export class RxStore {
  static use = defineStore('rx', () => {
    const sync = ref<boolean>(false)

    const { createIdentifier, parseIdentifier } = RxService.use()
    const profile = ProfileStore.use()
    const rxs = ref<Array<Rx>>([])

    // non persistent
    const staging = ref<Array<Rx>>([])

    function stage(rx: Rx) {
      rx.identifier = createIdentifier(rx)

      const existing = staging.value.find((x) => x.identifier == rx.identifier)

      if (existing) {
        existing.quantity = rx.quantity
        existing.frequency = rx.frequency
        existing.dosage = rx.dosage
        existing.identifier = rx.identifier
      } else {
        staging.value.unshift(rx)
      }
    }

    function init(ls: Array<Rx> | null = []) {
      rxs.value = [...(ls ?? [])]
      rxs.value.forEach((rx) => (rx.identifier = createIdentifier(rx)))
      sync.value = true
    }

    async function save(rx: Rx) {
      const api = PxApi.use().createProfilesClient()

      const { ndc: previousNdc } = parseIdentifier(rx.identifier!)

      const uuid = await api.saveRx(profile.id!, previousNdc, rx)

      const existing = rxs.value.find((x) => x.identifier == rx.identifier)

      const newIdentifier = createIdentifier(rx)

      if (existing) {
        existing.quantity = rx.quantity
        existing.frequency = rx.frequency
        existing.dosage = rx.dosage
        existing.identifier = newIdentifier
        existing.uuid = uuid
      } else {
        rx.identifier = newIdentifier
        rx.uuid = uuid
        rxs.value.unshift(rx)
      }
    }

    async function remove(identifier: string) {
      const api = PxApi.use().createProfilesClient()

      const existing = rxs.value.findIndex((x) => x.identifier == identifier)

      if (existing > -1) {
        const { externalId, ndc } = parseIdentifier(identifier)

        await api.deleteRx(profile.id!, externalId, ndc)
      } else {
        console.error(
          'Tried to remove rx that does not exist in the store. If this happens something is very wrong.'
        )
        return
      }

      rxs.value.splice(existing, 1)
    }

    function removeStaged(identifier: string) {
      const existing = staging.value.findIndex((x) => x.identifier == identifier)
      if (existing > -1) {
        staging.value.splice(existing, 1)
      }
    }

    return {
      sync,
      rxs,
      staging,
      init,
      save,
      remove,
      removeStaged,
      stage
    }
  })

  static init(ls: Array<Rx> | null = []) {
    const store = RxStore.use()
    store.init(ls)
  }
}
