import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'

export class IsAuthenticatedNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { isAuthenticated } = Auth.use()

    if (isAuthenticated.value) return this.yes
    else return this.no
  }
}
