import type { RouterConfig } from '@nuxt/schema'

// https://nuxt.com/docs/guide/going-further/custom-routing
// https://stackoverflow.com/questions/70833580/scrolling-a-page-in-vue-router-only-after-loading-the-components

// Prevent remembering scroll position on these pages
const ignoredPages = ['/dashboard']
export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition && !ignoredPages.includes(to.path)) {
        const resizeObserver = new ResizeObserver((entries) => {
          if (entries[0].target.clientHeight >= savedPosition.top + screen.height) {
            resolve(savedPosition)
            resizeObserver.disconnect()
          }
        })
        resizeObserver.observe(document.body)
      } else {
        resolve({ top: 0 })
      }
    })
  }
}
