import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { SessionStore } from '~/stores/session'

export class AwaitFootprintNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    const session = SessionStore.use()
    while(session.availablePlanTypes.length == 0) {
      console.log('Waiting for carrier footprint')
      await new Promise((r) => setTimeout(r, 200))
    }
    return Promise.resolve()
  }
}
