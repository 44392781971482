import { WaypointResult } from '~/generated/api-clients-generated'
import type { IPageNode } from '~/models/journey/waypoints'

export class WaypointAutoPilot {
  static use(waypoint: WaypointResult, queryParams: Object = {}) {
    const waypointProps = {
      ...WaypointEngine.defaultProps,
      getResult: () => waypoint,
      getQueryParams: () => queryParams
    }
    const { depart, next } = WaypointEngine.use(waypointProps)

    const engage = () => {
      return depart()
    }

    const getCourse = () => {
      const nextNode = next()
      return typeof nextNode === 'string' ? nextNode : (nextNode as IPageNode)?.path ?? null
    }

    return {
      engage,
      getCourse
    }
  }
}
