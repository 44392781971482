import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { ProfileStore } from '~/stores/profile'
import { PlanType, WaypointResult } from '~/generated/api-clients-generated'

export class CoverageTypeOrPlanTypeNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const profile = ProfileStore.use()

    // This may need to be more nuanced
    const result =
      _isNil(profile.needsAssessment.planType) || profile.needsAssessment.planType === PlanType.TM
        ? WaypointResult.CoverageTypeOrPlanType_Coverage
        : WaypointResult.CoverageTypeOrPlanType_Plan

    return this._destinations.get(result) ?? null
  }
}
