import type { PlanDetail, PlanQuote } from '~/generated/api-clients-generated'
import { PlanType } from '~/generated/api-clients-generated'
import { PharmacyType, PolicyType, SnpType } from '~/generated/api-clients-generated'
import { AdditionalBenefitsVm } from '~/models/quoting/AdditionalBenefits'
import { PlanRecommendationVm } from '~/models/quoting/PlanRecommendation'
import { DrugCoverageVm } from '~/models/quoting/DrugCoverage'
import { ProviderCoverageVm } from '~/models/quoting/ProviderCoverage'
import { PharmacyCoverageVm } from '~/models/quoting/PharmacyCoverage'
import { BenefitDetailsVm } from '~/models/quoting/BenefitDetails'
import MsPlanMatrix from '~/models/quoting/ms/MsPlanMatrix'

export class QuotedPlanVm {
  readonly medicareId: string
  readonly externalId: string
  readonly type: PlanType
  readonly snpType: SnpType
  readonly policyType: PolicyType
  readonly name: string
  readonly planLetter?: string | null
  readonly additionalBenefits: AdditionalBenefitsVm
  readonly details: PlanDetail
  readonly recommendation: PlanRecommendationVm
  readonly drugCoverage: DrugCoverageVm
  readonly providerCoverage: ProviderCoverageVm
  readonly pharmacyCoverage: PharmacyCoverageVm
  readonly benefitDetails: BenefitDetailsVm

  constructor(plan: PlanQuote, details: PlanDetail) {
    this.medicareId = plan.medicareId!
    this.externalId = plan.externalId!
    this.type = plan.type!
    this.snpType = plan.snpType!
    this.policyType = details.policyType!
    this.name = plan.name!
    this.planLetter = plan.planLetter
    this.additionalBenefits = new AdditionalBenefitsVm(plan.additionalBenefits ?? [])
    this.details = details
    this.recommendation = new PlanRecommendationVm(
      plan.recommendation!,
      plan.medicareId!,
      plan.type!
    )
    this.drugCoverage = new DrugCoverageVm(plan.drugCoverage)
    this.providerCoverage = new ProviderCoverageVm(plan.providerCoverage!)
    this.pharmacyCoverage = new PharmacyCoverageVm(plan.pharmacyCoverage!)
    this.benefitDetails = new BenefitDetailsVm(details)
  }

  get key() {
    return this.medicareId
  }

  get score() {
    return Math.floor(this.recommendation.picwellScore)
  }

  get rawScore() {
    return this.recommendation.picwellScore
  }

  get isPdp() {
    return this.type === PlanType.PDP
  }

  get isMedAdv() {
    return this.type === PlanType.MAPD || this.type === PlanType.MA
  }

  get isMs() {
    return this.type === PlanType.GAP
  }

  get msVm() {
    if (this.isMs && !!this.details.msType) {
      if (this.details.msType != 'N/A') {
        return MsPlanMatrix[this.details.msType] ?? null
      } else {
        return MsPlanMatrix[this.details.planLetter!.split(' ')[1]] ?? null
      }
    }
  }

  get hasDrugs() {
    return this.drugCoverage.count > 0
  }

  get hasPharmacy() {
    return this.pharmacyCoverage.pharmacies.length > 0
  }

  get hasProviders() {
    return this.providerCoverage.count > 0
  }

  get hasAdditionalBenefits() {
    return this.additionalBenefits.count > 0
  }

  get drugCost() {
    if (this.hasPharmacy && this.pharmacyCoverage.first!.pharmacyType == PharmacyType.Mail) {
      return this.drugCoverage.mailCost ?? this.recommendation?.drugCost
    }
    return (
      this.drugCoverage?.retailCost ?? this.drugCoverage?.mailCost ?? this.recommendation?.drugCost
    )
  }

  getYearlyEstCost(elements: Array<string>) {
    let total = 0

    if (elements.includes('plan_premiums')) total += (this.details.premium ?? 0) * 12.0
    if (elements.includes('drug_costs')) total += this.drugCost

    if (this.isPdp) return total

    if (elements.includes('oop')) total += this.recommendation.medicalCost ?? 0
    if (elements.includes('part_b_premium')) total += this.recommendation.annualPartBPremium ?? 0
    if (elements.includes('part_b_reduction')) {
      total -= this.recommendation.annualPartBReduction ?? 0
    }

    return total
  }
}
