import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { ProfileStore } from '~/stores/profile'
import { PlanType } from '~/generated/api-clients-generated'
import { Gender } from '~/generated/api-clients-generated'
import dayjs from 'dayjs'
import { SessionStore } from '~/stores/session'

export class SetDefaultEngineParamsNode extends ProcessingNodeBase {
  run(): Promise<void> {
    const profile = ProfileStore.use()
    const session = SessionStore.use()

    session.planType = PlanType.MAPD
    profile.needsAssessment.planType = PlanType.MAPD
    profile.gender = Gender.Male
    profile.dateOfBirth = dayjs().subtract(68, 'year').toDate()

    return Promise.resolve()
  }
}
