import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type {WaypointNode} from '~/models/journey/waypoints'
import type {Path} from '~/types/util'
import {ProfileStore} from '~/stores/profile'
import {SessionStore} from "~/stores/session"
import {PlanType} from "~/generated/api-clients-generated"
import { EngineStore } from "~/stores/engine"

export class IsRxPlanTypeNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const profile = ProfileStore.use()
    const session = SessionStore.use()
    const engine = EngineStore.use()

    const planType = ref<PlanType | null>(engine.pendingPlanType ?? profile.needsAssessment.planType ?? session.planType!)

    return this.yesNo(planType.value === PlanType.PDP)
  }
}
