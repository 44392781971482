import RedirectNodeBase from '~/models/journey/RedirectNodeBase'
import { SessionStore } from '~/stores/session'

export class QuoteDetailsRedirectNode extends RedirectNodeBase {
  async go(): Promise<boolean> {
    const { currentQuoteId } = SessionStore.use()
    const { push } = AppRouter.use()

    if (currentQuoteId) {
      await push(`/details/${currentQuoteId}`)
      return true
    } else return false
  }
}
