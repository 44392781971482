import { WaypointAuth, WaypointResult, WaypointType } from '~/generated/api-clients-generated'
import type { Path } from '~/types/util'

export type WaypointNode = IDecisionNode | IPageNode | IProcessingNode | IRedirectNode

export function isWaypointNode(x: WaypointNode | Path): x is WaypointNode {
  return typeof x !== 'string'
}

export function isPageNode(x: WaypointNode | Path): x is IPageNode {
  return isWaypointNode(x) && x.type === WaypointType.Page
}

export function isDecisionNode(x: WaypointNode | Path): x is IDecisionNode {
  return isWaypointNode(x) && x.type === WaypointType.Decision
}

export function isProcessingNode(x: WaypointNode | Path): x is IProcessingNode {
  return isWaypointNode(x) && x.type === WaypointType.Processing
}

export function isRedirectNode(x: WaypointNode | Path): x is IRedirectNode {
  return isWaypointNode(x) && x.type === WaypointType.Redirect
}

export interface IWaypointNode {
  type: WaypointType

  addDestination(result: WaypointResult, dest: WaypointNode | Path): void
}

export interface IDecisionNode extends IWaypointNode {
  type: WaypointType.Decision

  getNext(): WaypointNode | Path | null
}

// export interface ITransitionNode extends IWaypointNode {
//   kind: WaypointType.Transition
//   readonly path: string
//   readonly progressGroup: ProgressGroupEnum | null
//   readonly hasSiteBanner: boolean
//
//   getNext(result?: WaypointResultEnum)
// }

export interface IPageNode extends IWaypointNode {
  type: WaypointType.Page
  readonly path: string
  readonly component: string
  readonly layout: string
  readonly defaultVersion: string | null
  readonly replace: boolean
  readonly auth: WaypointAuth
  getNext(result: WaypointResult): WaypointNode | Path | null
}

export interface IProcessingNode extends IWaypointNode {
  type: WaypointType.Processing
  run(): Promise<void>
  getNext(): WaypointNode | Path | null
}

export interface IRedirectNode extends IWaypointNode {
  type: WaypointType.Redirect
  go(): Promise<boolean>
  getNext(): WaypointNode | Path | null
}

// export interface IRoutingNode extends IWaypointNode {
//   getNext(): RawLocation
// }
