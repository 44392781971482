<template>
  <NuxtLayout>
    <!--    removed page key because it was breaking application routing-->
    <!--    if something is breaking it might be this! -->
    <!--    <NuxtPage :page-key="$route.fullPath"/>-->
    <NuxtPage />
  </NuxtLayout>

  <SiteChatWidget />

  <client-only>
    <AuviousWidget />
  </client-only>
</template>

<script lang="ts" setup>
  import { SessionStore } from '~/stores/session'
  import { SiteStore } from '~/stores/site'
  import { CarrierStore } from '~/stores/carriers'

  const session = SessionStore.use()
  const { isHealthCheck, isIndex } = AppRoute.use()

  onMounted(async () => {
    TabSync.use()
    await AppInit.use()
    if (!process.dev) {
      const { start, isActive } = SessionReplay.use()
      const { isRobot } = RickDeckard.use()

      if (!isActive() && !isHealthCheck.value && !isRobot()) {
        start(session.profileId)
      }
    }
  })

  if (process.client) {
    const { $root, $rsReady } = useNuxtApp()
    const { track } = Analytics.use()
    const { push } = Gtm.use()

    const pxVisit = () => {
      if (_isNil(session.prevPage)) {
        setTimeout(() => {
          track('PxVisit', {
            from: document.referrer
          })
          push('PxVisit')
        }, 500)
      }
    }

    if ($rsReady) {
      pxVisit()
    } else {
      $root.on(RUDDERSTACK_READY, () => {
        pxVisit()
      })
    }

    const { isAuthenticated } = Auth.use()
    const { clearSessionId } = CookieJar.use()

    watch(isAuthenticated, async (val, old) => {
      if (old !== val && val === false) {
        clearSessionId()
        await SessionStore.reset()

        const site = SiteStore.use()
        const { push } = AppRouter.use()

        await push(site.journey.signOutPath!)
      }
    })
  }

  const site = SiteStore.use()
  await site.init()
  await CarrierStore.load()

  useSeoMeta({
    title: 'Healthpilot',
    robots: () => (isIndex.value ? 'index, nofollow' : 'noindex, nofollow')
  })

  useHead({
    script: [
      {
        src: 'https://assets.calendly.com/assets/external/widget.js',
        type: 'text/javascript',
        async: true
      }
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://assets.calendly.com/assets/external/widget.css'
      }
    ]
  })
</script>

<style scoped></style>
