import { defineStore } from 'pinia'
import type { Pharmacy } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'

export class PharmacyStore {
  static use = defineStore('pharmacy', () => {
    const sync = ref<boolean>(false)

    const profile = ProfileStore.use()

    const pharmacies = ref<Array<Pharmacy>>([])
    const staging = ref<Array<Pharmacy>>([])

    const preferredPharmacy = computed(() => pharmacies.value.find((x) => x.isPreferred))

    function init(ls: Array<Pharmacy> | null = []) {
      pharmacies.value = [...(ls ?? [])]
      sync.value = true
    }

    async function save(pharmacy: Pharmacy) {
      const api = PxApi.use().createProfilesClient()

      if (!pharmacies.value?.length) {
        pharmacy.isPreferred = true
      }

      const uuid = await api.savePharmacy(profile.id!, pharmacy)

      const existing = pharmacies.value.find((x) => x.npi == pharmacy.npi)
      if (!existing) {
        pharmacy.uuid = uuid
        pharmacies.value.unshift(pharmacy)
      }
    }

    async function remove(npi: string) {
      const api = PxApi.use().createProfilesClient()

      const index = pharmacies.value.findIndex((x) => x.npi == npi)
      const existing = index > -1 ? { ...pharmacies.value[index] } : null

      if (existing) {
        // Todo: Update the API to use NPI?
        await api.deletePharmacy(profile.id!, existing.externalId!)
        pharmacies.value.splice(index, 1)

        if (pharmacies.value?.length > 0 && existing.isPreferred) {
          const newPreferred = pharmacies.value[0]
          newPreferred.isPreferred = true
          await api.savePharmacy(profile.id!, newPreferred)
          setPreferred(newPreferred.npi!)
        }
      } else {
        console.error(
          'Tried to remove pharmacy that does not exist in the store. If this happens something is very wrong.'
        )
      }
    }

    function removeStaged(npi: string) {
      const existing = staging.value.findIndex((x) => x.npi == npi)
      if (existing > -1) {
        staging.value.splice(existing, 1)
      }
    }

    function setPreferred(npi: string) {
      let match: Pharmacy | null = null

      pharmacies.value.forEach((x) => {
        x.isPreferred = false
        if (x.npi === npi) {
          match = { ...x }
          x.isPreferred = true
        }
      })

      if (match) return

      staging.value.forEach((x) => {
        x.isPreferred = false
        if (x.npi === npi) {
          match = { ...x }
          x.isPreferred = true
        }
      })

      if (!match) {
        console.error('Failed to set preferred pharmacy for npi: ', npi)
      }
    }

    return {
      sync,
      pharmacies,
      staging,
      preferredPharmacy,
      init,
      save,
      remove,
      removeStaged,
      setPreferred
    }
  })

  static init(ls: Array<Pharmacy> | null = []) {
    const store = PharmacyStore.use()
    store.init(ls)
  }
}
