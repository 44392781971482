import WaypointNodeBase from '~/models/journey/WaypointNodeBase'
import type { IPageNode } from '~/models/journey/waypoints'
import type { Waypoint } from "~/generated/api-clients-generated"
import {
  WaypointAuth,
  WaypointResult,
  WaypointType
} from '~/generated/api-clients-generated'

export default class PageNode extends WaypointNodeBase implements IPageNode {
  type: WaypointType.Page = WaypointType.Page
  readonly path: string
  readonly component: string
  readonly defaultVersion: string | null
  readonly layout: string
  readonly replace: boolean
  readonly auth: WaypointAuth

  constructor(dto: Waypoint) {
    super()
    this.path = dto.path!
    this.component = dto.component!
    this.defaultVersion = dto.defaultVersion ?? null
    this.layout = dto.layoutOverride || 'default'
    this.replace = dto.replace || false
    this.auth = dto.auth || WaypointAuth.Public
  }

  getNext(result: WaypointResult) {
    return this._destinations.get(result) ?? null
  }
}
