import { SessionStore } from '~/stores/session'
import type { LDMultiKindContext } from 'launchdarkly-js-client-sdk'
import type {Ref} from "vue";

export type FlagRef<T> = Readonly<Ref<T>>

export class FeatureFlags {
  static use() {
    const session = SessionStore.use()
    const { $ld, $ldReady } = useNuxtApp()

    const getPreset = (key: string) => {
      const val = session.params[key] ?? session.params[_replace(key, /-/g, '_')]

      const normal = _lowerCase(val)

      if (normal === 'true') return true
      if (normal === 'false') return false

      return val
    }

    function captureValue(key: string, value: any) {
      if (!_isNil(value)) {
        session.captureFlag(key, value)
      }
    }

    // shamelessly pilfered from https://github.com/launchdarkly/vue-client-sdk/blob/main/src/getLDFlag.ts
    function flag<T>(flagKey: string, defaultFlagValue?: T): FlagRef<T> {
      const preset = getPreset(flagKey)
      if (!_isNil(preset)) {
        captureValue(flagKey, preset)
        return readonly(ref(preset))
      }

      const isLdReady = $ldReady.value
      const flagValue = isLdReady ? $ld.variation(flagKey, defaultFlagValue) : defaultFlagValue
      const flagRef = ref(flagValue)

      const updateFlagRef = (newFlagValue: unknown) => {
        flagRef.value = newFlagValue
        captureValue(flagKey, newFlagValue)
      }

      $ld.on(`change:${flagKey}`, updateFlagRef)

      onBeforeUnmount(() => $ld.off(`change:${flagKey}`, updateFlagRef))

      if (!isLdReady) {
        $ld.on('ready', () => updateFlagRef($ld.variation(flagKey, flagRef.value)))
      }

      captureValue(flagKey, flagRef.value)
      return readonly(flagRef)
    }

    function identify(key: string, anonymous: boolean = false, kind: string = 'user') {
      const context = $ld.getContext()
      const existingKind = 'kind' in context ? context.kind : 'user'

      if (existingKind === 'multi') {
        const multi = context as LDMultiKindContext
        multi[kind] = {
          key: key,
          anonymous: anonymous
        }
        $ld.identify(multi)
      } else if (existingKind !== kind) {
        const multi = {
          kind: 'multi',
          [existingKind]: {
            key: context.key,
            anonymous: context.anonymous
          },
          [kind]: {
            key: key,
            anonymous: anonymous
          }
        }
        $ld.identify(multi)
      } else {
        $ld.identify({
          kind: kind,
          key: key,
          anonymous: anonymous
        })
      }
    }

    return {
      flag,
      identify
    }
  }
}
