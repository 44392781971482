export default class ActionGuard {
  static use() {
    const { flag } = FeatureFlags.use()

    const actionsEnabled = computed(() => flag<boolean>('enable-quote-actions').value)

    const maybeDo = (cb: () => void) => {
      if (actionsEnabled.value) {
        cb()
      }
    }

    return {
      maybeDo,
      actionsEnabled
    }
  }
}
