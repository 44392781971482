import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import undefined from '~/components/page/undefined.vue'
import type { Path } from '~/types/util'
import { ProfileStore } from '~/stores/profile'

export class ShowGettingStartedNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const profile = ProfileStore.use()
    return this.yesNo(profile.needsAssessment.coverageGoals!.length > 1)
  }
}
