import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { EngineStore } from '~/stores/engine'
import { SessionStore } from '~/stores/session'
import { PlanType } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'

export class IsLimitedChoiceNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const engine = EngineStore.use()
    const profile = ProfileStore.use()
    const session = SessionStore.use()

    const { flag } = FeatureFlags.use()
    const isLimitedChoice = flag('limited-choice', false).value
    const limitedChoiceEmpty = flag('limited-choice-empty', false).value

    return this.yesNo(
      profile.needsAssessment.planType !== PlanType.GAP &&
        profile.needsAssessment.planType === session.planType &&
        (!engine.isEmptyState || !!limitedChoiceEmpty) &&
        !!isLimitedChoice
    )
  }
}
