import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { SessionStore } from '~/stores/session'

export class IsNewToMedicareNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const session = SessionStore.use()
    return this.yesNo(!session.hasMedicare!)
  }
}
