import WaypointNodeBase from '~/models/journey/WaypointNodeBase'
import type { IProcessingNode, WaypointNode } from '~/models/journey/waypoints'
import { WaypointResult, WaypointType } from '~/generated/api-clients-generated'
import type { Path } from '~/types/util'

export default abstract class ProcessingNodeBase
  extends WaypointNodeBase
  implements IProcessingNode
{
  type: WaypointType.Processing = WaypointType.Processing

  abstract run(): Promise<void>

  getNext(): WaypointNode | Path | null {
    return this._destinations.get(WaypointResult.Next) ?? null
  }
}
