import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'

export class ShowIepContextNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    const iepContext = flag<boolean>('iep-context')

    return this.yesNo(iepContext.value)
  }
}
