import { ProfileStore } from '~/stores/profile'
import { SessionStore } from '~/stores/session'

export class Gtm {
  static use() {
    const push = (event: string, props: any = {}) => {
      if (process.client) {
        const profile = ProfileStore.use()
        const session = SessionStore.use()

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: event,
          ms_email: formatEmailMs(profile.emailAddress?.address),
          ms_phone: formatPhoneMs(profile.mobilePhone?.number),
          meta_email: profile.emailAddress?.address,
          meta_phone: formatPhone(profile.mobilePhone?.number),
          first_name: profile.firstName,
          last_name: profile.lastName,
          postal_code: profile.location?.zipCode,
          region: profile.location?.stateCode,
          country: 'US',
          ...session.params,
          ...props
        })
      }
    }

    const formatEmailMs = (value: string | null | undefined) => {
      if (_isNil(value)) return null

      const parts = value.split('@')
      if (parts.length != 2) return null

      const sanitized = parts[0].replaceAll('.', '').replaceAll('+', '')
      return `${sanitized}@${parts[1]}`
    }

    const formatPhone = (value: string | null | undefined) => {
      if (_isNil(value) || value.length < 10) return null

      const normal = _filter(_toArray(value), (x) => _isFinite(_parseInt(x))).join('')

      if (normal.length === 10) return `1${normal}`
      if (normal.length === 11 && _startsWith(normal, '1')) return normal
      return null
    }

    const formatPhoneMs = (value: string | null | undefined) => {
      const phone = formatPhone(value)
      return _isNil(phone) ? null : `+${phone}`
    }

    return {
      push
    }
  }
}
