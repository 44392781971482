import { SessionStore } from '~/stores/session'
import { ProfileStore } from '~/stores/profile'
import { EngineStore } from '~/stores/engine'

export class StoreWatchers {
  static use() {
    const session = SessionStore.use()
    const profile = ProfileStore.use()
    const engine = EngineStore.use()

    const { tagEvent } = SessionReplay.use()

    watch(
      [() => profile.id, () => session.profileId],
      ([profileId, sessionProfileId]) => {
        if (!profileId) {
          tagEvent('ProfileIdNull')
        }
        if (!sessionProfileId) {
          tagEvent('SessionProfileIdNull')
        }
      },
      { immediate: true }
    )

    watchEffect(
      () => {
        const engineId = profile.engineId

        if (!_isNil(engineId)) {
          engine.load(engineId)
        } else {
          engine.$reset()
        }
      },
      {
        flush: 'post'
      }
    )

    watch(
      [() => session.planType, () => session.planYear, () => profile.location.fipsCountyCode],
      ([planType, planYear, fips], [planTypeOld, planYearOld, fipsOld]) => {
        if (!_isNil(planTypeOld) || !_isNil(planYearOld) || !_isNil(fipsOld)) {
          if (session.planList !== 'preferredPharmacy') {
            session.planList = 'all'
          }
          session.quoteFilters = QuoteFilters.empty(session.planYear)
          session.plandalfPreferences = []
        }

        if (!_isNil(fipsOld) && fips != fipsOld) {
          session.plandalfRecentlyViewedMapd = {}
          session.plandalfRecentlyViewedPdp = {}
        }
      }
    )

    watch(
      [() => profile.location?.fipsCountyCode, () => session.planYear],
      ([fipsVal, yearVal]) => {
        console.log('FOOTPRINT', fipsVal, yearVal)
        if (!_isEmpty(fipsVal) && !_isNil(yearVal)) {
          SessionStore.runCarrierFootprint()
        }
      },
      { immediate: true }
    )

    watch(
      () => profile.needsAssessment.planType,
      (val) => {
        if (profile.sync && !_isNil(val)) {
          const { planTypeSelected } = QuoteAnalytics.use()
          planTypeSelected(val)
        }
      }
    )

    watch(
      () => session.planList,
      (val, oldValue) => {
        if (!_isNil(val) && val !== oldValue) {
          session.quoteFilters = { ...QuoteFilters.empty(session.planYear), planList: val }
        }
      }
    )

    watch(
      () => session.planYear,
      (val, old) => {
        if (!val) {
          session.planYear = old
          tagEvent('NullPlanYear')
        }
      }
    )

    watch(
      () => engine.params.doctors,
      (val, oldVal) => {
        if (!!val && !!oldVal && val.length < oldVal.length) {
          const removals = _difference(
            oldVal.map((x) => x.npi!),
            val.map((x) => x.npi!)
          )
          session.quoteFilters['providers'] = _without(session.quoteFilters.providers, ...removals)
        }

        if ((_isNil(val) || val.length < 1) && session.quoteSortOrder === 'docs_then_est_costs') {
          session.quoteSortOrder = 'default'
        }
      }
    )

    watch(
      () => engine.params.rxs,
      (val, oldVal) => {
        if (!!val && !!oldVal && val.length < oldVal.length) {
          const removals = _difference(
            oldVal.map((x) => x.ndc!),
            val.map((x) => x.ndc!)
          )
          session.quoteFilters['rxs'] = _without(session.quoteFilters.rxs, ...removals)
        }
      }
    )

    watch(
      () => engine.params.additionalBenefits,
      (val, oldVal) => {
        if (!!val && !!oldVal && val.length < oldVal.length) {
          const removals = _difference(oldVal, val)

          session.quoteFilters['benefits'] = _without(session.quoteFilters.benefits, ...removals)
        }
      }
    )
  }
}
