import { EngineStore } from '~/stores/engine'
import { ProviderStore } from '~/stores/provider'
import { RxStore } from '~/stores/rx'
import { AdditionalBenefitType } from '~/generated/api-clients-generated'
import { PlandalfStore } from '~/stores/plandalf'
import type { ConvoOptionConfig } from '~/models/conversation/ConfigModels'
import { ProfileStore } from '~/stores/profile'

export class PlandalfOptions {
  static use() {
    const engine = EngineStore.use()
    const profile = ProfileStore.use()
    const providerStore = ProviderStore.use()
    const rxStore = RxStore.use()
    const plandalf = PlandalfStore.use()

    const rxOptions = computed(() =>
      _sortBy(
        rxStore.rxs.map(
          (x) =>
            ({
              id: x.dosage?.ndc,
              value: x,
              text: x.drugName,
              icon: 'pill',
              active: plandalf.preferences
                .find((x) => x.type == 'drugs')
                ?.values.includes(x.dosage?.ndc!),
              disabled: !engine.quotes.some((q) =>
                q.drugCoverage.coverageSummaries?.some(
                  (cs) => cs.isCovered && cs.ndc == x.dosage?.ndc
                )
              )
            } as ConvoOptionConfig)
        ),
        ['disabled', 'text']
      )
    )

    const providerOptions = computed(() =>
      _sortBy(
        providerStore.providers.map(
          (x) =>
            ({
              id: x.npi,
              value: x,
              text: x.displayName,
              icon: 'doctor',
              active: plandalf.preferences
                .find((x) => x.type == 'doctors')
                ?.values.includes(x.npi!),
              disabled: !engine.quotes.some((q) =>
                q.providerCoverage.providers?.some((p) => p.inNetwork && p.npi == x.npi)
              )
            } as ConvoOptionConfig)
        ),
        ['disabled', 'text']
      )
    )

    const carrierOptions = computed(() =>
      _sortBy(
        _uniqBy(
          engine.getFilterPreview(['carriers']).map((x) => ({
            value: x.details.carrierFilterKey,
            text: x.details.carrierFilterKey
          })),
          'value'
        ).map(
          (x) =>
            ({
              id: x.value,
              value: x.value,
              text: x.text,
              active: plandalf.preferences
                .find((x) => x.type == 'carriers')
                ?.values.includes(x.value!)
            } as ConvoOptionConfig)
        ),
        ['text']
      )
    )

    function getRandomCarriers(count: number) {
      let randomCarriers = _shuffle(carrierOptions.value.map((x) => x.text))
      if (randomCarriers.length > count) randomCarriers = randomCarriers.slice(0, count)

      return randomCarriers
    }

    const additionalBenefitsOptions = computed(() => [
      {
        value: AdditionalBenefitType.Vision,
        text: 'Vision',
        icon: 'glasses'
      },
      {
        value: AdditionalBenefitType.Hearing,
        text: 'Hearing',
        icon: 'ear'
      },
      {
        value: AdditionalBenefitType.Dental,
        text: 'Dental',
        icon: 'tooth'
      },
      {
        value: AdditionalBenefitType.Fitness,
        text: 'Fitness',
        icon: 'fitness-watch'
      },

      {
        value: AdditionalBenefitType.DomesticTravel,
        text: 'Travel (US)',
        icon: 'car'
      },
      {
        value: AdditionalBenefitType.OtcMedications,
        text: 'Over the counter medications',
        icon: 'pill'
      },
      {
        value: AdditionalBenefitType.Transportation,
        text: 'Transportation',
        icon: 'bus'
      }
    ])

    const benefitOptions = computed(() =>
      additionalBenefitsOptions.value
        .filter((x) => profile.needsAssessment.additionalBenefits?.includes(x.value))
        // .filter((x) =>
        //   engine.getFilterPreview(['benefits']).some((q) => {
        //     const benefit: string = PlanBenefitType[x.value]
        //     return q.details.selectedBenefits![benefit]
        //   })
        // )
        .map(
          (x) =>
            ({
              id: String(x.value),
              value: x.value,
              text: x.text,
              icon: x.icon,
              active: plandalf.preferences
                .find((x) => x.type == 'benefits')
                ?.values.includes(+x.value!)
            } as ConvoOptionConfig)
        )
    )

    const policyTypeOptions = computed(() => {
      const types: string[] = []
      // if (plandalfStore.filteredPlans.some((x) => ppoTypes.includes(x.policyType))) {
      //   types.push('ppo')
      // }
      // if (plandalfStore.filteredPlans.some((x) => hmoTypes.includes(x.policyType))) {
      //   types.push('hmo')
      // }
      types.push('ppo')
      types.push('hmo')
      return types
    })

    return {
      rxOptions,
      providerOptions,
      carrierOptions,
      additionalBenefitsOptions,
      benefitOptions,
      policyTypeOptions,
      getRandomCarriers
    }
  }
}
