import type { AdditionalBenefit } from '~/generated/api-clients-generated'

export class AdditionalBenefitsVm {
  readonly additionalBenefits: AdditionalBenefit[]

  constructor(additionalBenefits: AdditionalBenefit[]) {
    this.additionalBenefits = _sortBy(additionalBenefits, [(b) => b.sortKey])
  }

  get count() {
    return this.additionalBenefits.length
  }

  get coveredCount() {
    return this.covered.length
  }

  get covered() {
    return this.additionalBenefits.filter((b) => b.covered)
  }

  get notCovered() {
    return this.additionalBenefits.filter((b) => !b.covered)
  }

  get allCovered() {
    return this.coveredCount === this.count
  }

  isCovered(name: string) {
    return this.additionalBenefits.find((b) => b.name === name)!.covered
  }
}
