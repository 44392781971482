import type { Guid } from '~/types/util'
import { ProfileStore } from '~/stores/profile'
import { SessionStore } from '~/stores/session'

export class Integrations {
  static setIdentity(profileId: Guid) {
    if (process.client) {
      const { identify: analyticsIdentify } = Analytics.use()
      const { identify: flagsIdentify } = FeatureFlags.use()
      const { identify: sentryIdentify } = Sentry.use()
      const { identify: replayIdentify } = SessionReplay.use()

      const profile = ProfileStore.use()
      const { sessionId } = CookieJar.use()

      const traits = {
        email: profile.emailAddress?.address,
        mobilePhone: profile.mobilePhone?.number,
        sessionId: sessionId.value
      }

      analyticsIdentify(profileId, traits)
      flagsIdentify(profileId)
      sentryIdentify(profileId)
      replayIdentify(profileId)
    }
  }
}
