<template>
  <client-only>
    <TalkdeskChatWidget
      :context="context"
      :hide-launcher="!show"
      :show-notifications="true"
      :launcher-position-class="launcherClasses"
      :window-bottom="!show ? 0 : 80"
      enable-virtual-agent
      :disable="!actionsEnabled"
    />
  </client-only>
</template>

<script lang="ts" setup>
  import { ProfileStore } from '~/stores/profile'

  const profile = ProfileStore.use()

  const { actionsEnabled } = ActionGuard.use()

  const context = computed(() => ({
    profile_id: profile.id,
    td_contact_person_identification: profile.emailAddress.address ?? profile.mobilePhone.number
  }))

  const { breakpoints } = Breakpoints.use()
  const show = computed(() => breakpoints.greaterOrEqual('md').value)

  const launcherClasses = computed(() => ['bottom-3', 'right-[20px]', 'z-[50]'])
</script>

<style scoped></style>
