import type { PharmacyCoverage, PharmacyCoverageDetail } from '~/generated/api-clients-generated'

export enum PharmacyCoverageLevel {
  Preferred,
  Standard,
  OutOfNetwork
}

export class PharmacyCoverageVm {
  readonly pharmacies: Array<PharmacyCoverageDetail>

  constructor(raw: PharmacyCoverage) {
    this.pharmacies = raw?.pharmacies || []
  }

  get first() {
    if (this.pharmacies.length === 0) return null
    return this.pharmacies[0]
  }

  get inNetwork() {
    return this.first?.inNetwork
  }

  get preferred() {
    return this.first?.isPreferred
  }

  get coverageLevel(): PharmacyCoverageLevel {
    return this.preferred
      ? PharmacyCoverageLevel.Preferred
      : this.inNetwork
      ? PharmacyCoverageLevel.Standard
      : PharmacyCoverageLevel.OutOfNetwork
  }

  get coverageDescription(): string {
    switch (this.coverageLevel) {
      case PharmacyCoverageLevel.Preferred:
        return 'Preferred'
      case PharmacyCoverageLevel.Standard:
        return 'Standard'
      case PharmacyCoverageLevel.OutOfNetwork:
        return 'Out-of-network'
    }
  }
}
