import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import {SessionStore} from "~/stores/session";

export class GetPlanTypeRecommendationNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    const session = SessionStore.use()

    await session.getPlanTypeRecommendations()
    return Promise.resolve(undefined)
  }
}
