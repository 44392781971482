import type { Costs, PlanRecommendation, Score } from '~/generated/api-clients-generated'
import { PlanType } from '~/generated/api-clients-generated'

export class PlanRecommendationVm {
  readonly medicareId: string
  readonly planType: PlanType
  readonly score?: Score | null
  readonly costs?: Costs | null

  constructor(rec: PlanRecommendation | null, medicareId: string, planType: PlanType) {
    this.medicareId = medicareId
    this.planType = planType
    this.score = rec?.score
    this.costs = rec?.costs
  }

  get totalCost() {
    if (!this.costs) return 0
    return Number(this.costs.full?.realCost?.mean?.toFixed(2) ?? 0)
  }

  get drugCost() {
    if (!this.costs) return 0
    return Number(this.costs.full?.drugs?.retail?.total?.toFixed(2) ?? 0)
  }

  get medicalCost() {
    if (!this.costs) return null
    return Number(this.costs.full?.services?.inNetwork?.mean?.toFixed(2) ?? 0)
  }

  get premium() {
    if (!this.costs) return null
    return Number(this.costs.full?.monthlyPremium?.toFixed(2) ?? 0)
  }

  get annualPremium() {
    return (this.premium ?? 0) * 12
  }

  get partBPremium() {
    if (!this.costs) return null
    return Number(this.costs.full?.partB?.monthlyPremium?.toFixed(2) ?? 0)
  }

  get annualPartBPremium() {
    if (_isNil(this.partBPremium)) return null
    return this.partBPremium * 12
  }

  get partBReduction() {
    if (!this.costs) return null
    return Number(this.costs.full?.partB?.monthlyPremiumReduction?.toFixed(2) ?? 0)
  }

  get annualPartBReduction() {
    if (_isNil(this.partBReduction)) return null
    return this.partBReduction * 12
  }

  get picwellScore() {
    if (!this.score) return 0
    if (!!this.score.retail?.picwellScore) return this.score.retail.picwellScore
    return this.score.mail?.picwellScore || 0
  }

  calculateSavings(other: PlanRecommendationVm | null) {
    if (!this.totalCost || !other) return undefined
    if (this.planType === PlanType.PDP && other.planType !== PlanType.PDP)
      return Number(other.drugCost.toFixed(2)) - Number(this.totalCost.toFixed(2))

    return Number(other.totalCost.toFixed(2)) - Number(this.totalCost.toFixed(2))
  }
}
