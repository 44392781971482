import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type {WaypointNode} from '~/models/journey/waypoints'
import type {Path} from '~/types/util'
import {SessionStore} from '~/stores/session'
import {PlanType, WaypointResult} from "~/generated/api-clients-generated"
import {ProfileStore} from "~/stores/profile"

export class LimitedPlanTypeSupplyNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const session = SessionStore.use()
    const profile = ProfileStore.use()

    function getResult() {
      if (session.availablePlanTypes.length > 1) {
        return WaypointResult.Next
      }
      if (session.availablePlanTypes.length == 1) {
        profile.needsAssessment.planType = session.availablePlanTypes[0]
        session.planType = session.availablePlanTypes[0]
        switch (session.availablePlanTypes[0]) {
          case PlanType.MAPD:
            console.log(`ONLY MAPD PLANS AVAILABLE`)
            return WaypointResult.PlanType_MA
          case PlanType.PDP:
            console.log(`ONLY PDP PLANS AVAILABLE`)
            return WaypointResult.PlanType_PDP
          case PlanType.GAP:
            console.log(`ONLY MS PLANS AVAILABLE`)
            return WaypointResult.PlanType_MS
        }
      }
    }
    return this._destinations.get(getResult() ?? WaypointResult.Next) ?? null
  }
}

