export class MsConstants {
  static readonly PartADeductibleAmount = 1600
  static readonly PartBDeductibleAmount = 226
  static readonly AnnualDeductibleForHighDedPlans = 2700
  static readonly PlanKOopLimit = 6940
  static readonly PlanLOopLimit = 3470
}

export type MsPlanOptions = {
  planLetter: string
  isHighDeductible: boolean
  partADeductiblePct: number
  partBDeductiblePct: number
  partBCoinsurancePct: number
}

function formatCarrierName(name?: string | null): string | null {
  if (!name) {
    return null
  }
  return name.replace('Default', '').trim()
}

export class MsPlanViewModel {
  readonly planLetter: string
  readonly isHighDeductible: boolean
  readonly partADeductiblePct: number
  readonly partBDeductiblePct: number
  readonly partBCoinsurancePct: number

  constructor(opts: MsPlanOptions) {
    this.planLetter = opts.planLetter
    this.isHighDeductible = opts.isHighDeductible
    this.partADeductiblePct = opts.partADeductiblePct
    this.partBDeductiblePct = opts.partBDeductiblePct
    this.partBCoinsurancePct = opts.partBCoinsurancePct
  }

  // Amounts
  get annualDeductible() {
    return this.isHighDeductible ? MsConstants.AnnualDeductibleForHighDedPlans : null
  }

  get partADeductible() {
    return this.isHighDeductible
      ? null
      : MsConstants.PartADeductibleAmount * this.partADeductiblePct
  }

  get partBDeductible() {
    return this.isHighDeductible
      ? null
      : MsConstants.PartBDeductibleAmount * this.partBDeductiblePct
  }

  get oopLimit() {
    switch (this.planLetter) {
      case 'K':
      case 'WI_50':
      case 'MN_50':
        return MsConstants.PlanKOopLimit
      case 'L':
      case 'WI_25':
      case 'MN_75':
        return MsConstants.PlanLOopLimit
      default:
        return null
    }
  }

  // Formatted amounts
  get fmtAnnualDeductible() {
    return this.annualDeductible ? formatUsd(this.annualDeductible) : null
  }

  get fmtPartADeductible() {
    return this.partADeductible != null ? formatUsd(this.partADeductible) : null
  }

  get fmtPartBDeductible() {
    return this.partBDeductible != null ? formatUsd(this.partBDeductible) : null
  }

  get fmtOopLimit() {
    return this.oopLimit ? formatUsd(this.oopLimit) : 'N/A'
  }

  get fmtPartADeductiblePct() {
    return formatPct(this.partADeductiblePct)
  }

  get fmtPartBDeductiblePct() {
    return formatPct(this.partBDeductiblePct)
  }

  get fmtPartBCoinsurance() {
    return this.partBCoinsurancePct === 0
      ? formatUsd(this.partBCoinsurancePct)
      : formatPct(this.partBCoinsurancePct)
  }

  get fmtPartBCoinsuranceOffice() {
    // Minnesota Copay plan
    if (this.planLetter === 'MN_BPTBC') return `Up to ${formatUsd(50)}`
    else return this.fmtPartBCoinsurance
  }

  get fmtPartBCoinsuranceER() {
    // Minnesota Copay plan
    if (this.planLetter === 'MN_BPTBC') return `Up to ${formatUsd(25)}`
    else return this.fmtPartBCoinsurance
  }

  get fmtPartBCoinsuranceAmbulance() {
    // Minnesota Copay plan
    if (this.planLetter === 'MN_BPTBC') return formatUsd(0)
    else return this.fmtPartBCoinsurance
  }

  get fmtHospitalCosts() {
    return formatUsd((this.partADeductible || 0) * this.partADeductiblePct)
  }

  premiumModalUnderwriting() {
    const doNotShow = ['Core', 'Supp1A', 'Supp1']

    if (doNotShow.includes(this.planLetter)) {
      return ''
    }

    return (
      'Your application may be subject to underwriting by the insurance carrier' +
      ' if you did not turn 65 within the last 6 months and you do not qualify for a guaranteed issue period.' +
      ' Premium rates may be higher than what is estimated if you receive a non-preferred rate.'
    )
  }

  medicalDeductibleModalTitle() {
    if (this.isHighDeductible) {
      return 'Annual Deductible'
    }

    return 'Deductible'
  }

  /**
   * Additional text for overlays
   */
  medicalDeductibleDetails(carrierName?: string | null) {
    /**
     * Remove 'Default' from the carrier name string.
     */
    carrierName = formatCarrierName(carrierName)

    if (!carrierName) {
      return []
    }

    if (this.isHighDeductible) {
      return [
        `Your Annual Deductible is the amount of money you have to pay each year` +
          ` for covered services before Medicare and ${carrierName} pay the remainder of the expense.` +
          ` For this plan the annual deductible is ${this.fmtAnnualDeductible}.`
      ]
    }
    if (!this.partBDeductible) {
      return []
    }

    console.log('PLAN LETTER: ', this.planLetter)

    if (this.planLetter === 'Core') {
      return [
        `Your Part B deductible is the amount of money you have to pay each year for Part B covered medical expenses (e.g. doctor, outpatient services, etc..) before Medicare and ${carrierName} pay the remainder of the expense.`
      ]
    }

    let first = [
      `Your Part B deductible is the amount of money you have to pay each year for Part B covered medical expenses before Medicare and ${carrierName} pay the remainder of the expense.`
    ]

    if (this.planLetter === 'Supp1A') {
      return first
    }

    /**
     * Jira issue PX-903: For Plans C and F, should say: “In this case <carrier-name> pays for 100% of the deductible.”
     */
    if (['C', 'F', 'Supp1', 'MN_HDM'].includes(this.planLetter)) {
      return [...first, `In this case ${carrierName} pays for 100% of the deductible.`]
    }

    if (this.partBDeductible > 0) {
      switch (this.planLetter) {
        case 'WI_25':
          return [
            ...first,
            `For the 25% Cost Sharing Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        case 'WI_50':
          return [
            ...first,
            `For the 50% Cost Sharing Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        case 'WI_B':
          return [
            ...first,
            `For the Basic Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        case 'WI_HDM':
          return [
            ...first,
            `For the High Deductible Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        case 'MN_75':
          return [
            ...first,
            `For the 75% Cost Sharing Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        case 'MN_50':
          return [
            ...first,
            `For the 50% Cost Sharing Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        case 'MN_BPTBC':
          return [
            ...first,
            `For the Copayment Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        case 'MN_B':
          return [
            ...first,
            `For the Basic Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        case 'MN_EBM':
          return [
            ...first,
            `For the Extended Basic Plan you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
        default:
          return [
            ...first,
            `For plan ${this.planLetter} you're paying ${this.fmtPartBDeductiblePct} of the deductible.`
          ]
      }
    }

    return [...first, `In this case ${carrierName} pays for 100% of the deductible.`]
  }

  partBCoinsuranceDetails(carrierName?: string | null) {
    if (!carrierName) return []
    return [
      `Medicare will pay 80% of the pre-determined amount`,
      `${carrierName} will pay ${formatPct(1 - 0.8 - this.partBCoinsurancePct)}`,
      `You'll pay the remaining ${this.fmtPartBCoinsurance} until you reach your yearly ${this.fmtOopLimit} limit.  After that ${carrierName} will pay for all additional covered expenses.`
    ]
  }

  hospitalCoverageDetails(carrierName?: string | null) {
    if (!carrierName) return []
    if (this.isHighDeductible)
      return [
        `Your hospital costs will be covered by Medicare and ${carrierName} after you pay your annual deductible of ${this.fmtAnnualDeductible}`
      ]
    if (this.partADeductiblePct === 0) return null

    let first = [
      `Your Hospital deductible is the yearly amount of money you have to pay for Part A Hospital costs you may incur before the Medicare program pays for coverage.`
    ]

    if (this.partADeductiblePct === 1) {
      return [...first, `In this case you pay ${this.fmtPartADeductiblePct} of the deductible.`]
    }

    return [
      ...first,
      `In this case you pay ${
        this.fmtPartADeductiblePct
      } of the deductible while ${carrierName} would pay the other ${formatPct(
        1 - this.partADeductiblePct
      )}.`
    ]
  }

  hospitalAdditonalDaysNote() {
    // wisconsin
    if (this.planLetter.startsWith('WI_')) {
      return '(Note: you pay costs after the visit exceeds 515 days and if the additional 175 lifetime days of inpatient psychiatric hospital care are used.)  This is not correct for the mental health days; plan will pay 175 after the Medicare covered stays - the post-lifetime reserve benefit days of 360 does not apply to mental health.'
    }

    switch (this.planLetter) {
      case 'Core':
        return '(Note: you pay costs after the visit exceeds 515 days or if a mental health hospital visit not covered by Medicare exceeds 60 days)'
      case 'Supp1':
      case 'Supp1A':
        return '(Note: you pay costs after the visit exceeds 515 days or if a mental health hospital visit not covered by Medicare exceeds 120 days)'
      default:
        return '(Note: you pay costs after the visit exceeds 515 days)'
    }
  }

  officeVisitNote() {
    if (this.planLetter.startsWith('MN_')) {
      return '(Note: you pay 50% of costs for outpatient mental health visits not covered by Medicare.)'
    }
    return null
  }

  // Vision, hearing, etc.
  dentalCoverageDetails(brandId?: string | number | null) {
    switch (brandId) {
      // UHC/AARP
      case '75':
      case 75:
        return [
          'In-network discounts generally average 30-40% off of contracted rates nationally for a range of dental services, including cleanings, exams, fillings and crowns.',
          'Access to 30K in-network general dentists and specialists at 90K locations nationwide.',
          'No waiting periods, deductibles, or annual maximums.',
          'The Dentegra dental discount is not insurance.'
        ]
      // Humana Health Living
      case 118:
      case '118':
        return [
          'Includes 100% of two routine exams and cleanings and one bite-wing X-ray each year, 50% coverage on fillings and 75% coverage for extractions (in-network). See the Outline of Coverage for additional information including additional benefits not listed here.'
        ]
      default:
        return []
    }
  }

  visionCoverageDetails(brandId?: string | number | null) {
    switch (brandId) {
      // UHC/AARP
      case '75':
      case 75:
        return [
          'At LensCrafters, take an additional $50 off the AARP® Vision Discount provided by EyeMed or best in-store offer on no-line progressive lenses with frame purchase.',
          '$50 eye exams at participant providers.'
        ]
      // Humana Health Living
      case 118:
      case '118':
        return [
          'Includes $0 copay for annual In Network vision exams, $75 toward annual Out of Network exams, as well as $100 toward frames and lenses or contact lenses annually. See the Outline of Coverage for additional information including additional benefits not listed here.'
        ]
      default:
        return []
    }
  }

  hearingCoverageDetails(brandId?: string | number | null) {
    switch (brandId) {
      // UHC/AARP
      case '75':
      case 75:
        return [
          'The AARP member rate plus an additional $100 discount on hearing devices in the top 5 tiers of technology and features, ranging from standard to premium.',
          "Extended warranties on many of HearUSA's digital hearing aids.",
          'Your very own hearing health support team.'
        ]
      default:
        return []
    }
  }

  additionalBenefits(
    brandId?: string | number | null
  ): Array<{ name: string; covered: string | boolean }> {
    switch (brandId) {
      // UHC/AARP
      case '75':
      case 75:
        return [
          { name: 'Dental', covered: true },
          { name: 'Vision', covered: true },
          { name: 'Hearing', covered: true }
        ]
      default:
        return []
    }
  }
}
