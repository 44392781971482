import { defineStore } from 'pinia'
import type { Journey } from '~/generated/api-clients-generated'
import { PlanType } from '~/generated/api-clients-generated'
import { JourneyBuilder } from '~/models/journey/JourneyBuilder'
import type { JourneyVm } from '~/models/journey/JourneyBuilder'
import type { ComponentContent, TooltipContent, StandbyTestimonialContent } from '~/types/cms'
import { contentKey } from '~/utils/cms'
import type { Catalog } from '~/types/util'

export class SiteStore {
  static use = defineStore('site', () => {
    const journeyData = ref<Journey[]>([])
    const tooltipContentData = ref<Array<TooltipContent>>([])
    const componentContentData = ref<Array<ComponentContent>>([])
    const standbyTestimonialContentData = ref<Array<ComponentContent>>([])

    const applicationsCarouselIndex = ref<number | null>(null)
    const applicationPlanTypeSelection = ref<PlanType | null>(null)
    const savedPlansCarouselIndex = ref<number | null>(null)
    const savedPlanTypeSelection = ref<PlanType | null>(null)
    const enrollmentCarouselIndex = ref<number | null>(null)

    // const journey = computed(() => {
    //   if (_isNil(journeyData.value)) return JourneyBuilder.empty()
    //   return JourneyBuilder.build(journeyData.value)
    // })

    const journey = ref<JourneyVm>(JourneyBuilder.empty())

    const tooltipContent = computed(() =>
      tooltipContentData.value.reduce((acc, item) => {
        acc[contentKey(item.type, item.version)] = item
        return acc
      }, {} as Catalog<TooltipContent>)
    )

    const standbyTestimonialContent = computed(() =>
      standbyTestimonialContentData.value.reduce((acc, item) => {
        acc[contentKey(item.type, item.version)] = item
        return acc
      }, {} as Catalog<StandbyTestimonialContent>)
    )

    const componentContent = computed(() =>
      componentContentData.value.reduce((acc, item) => {
        acc[contentKey(item.type, item.version)] = item
        return acc
      }, {} as Catalog<ComponentContent>)
    )

    async function init() {
      const {
        getComponentContentCollection,
        getTooltipContentCollection,
        getStandbyTestimonialContentCollection
      } = Cms.use()
      const csPromise = getComponentContentCollection()
      const tsPromise = getTooltipContentCollection()
      const sbtPromise = getStandbyTestimonialContentCollection()

      const { createSiteClient } = PxApi.use()
      const $site = createSiteClient()

      const { data } = await useAsyncData('site', async () => {
        return await $site.allJourneys()
      })

      journeyData.value = data.value!

      const { content: csContent } = await csPromise
      componentContentData.value = csContent.value

      const { content: tsContent } = await tsPromise
      tooltipContentData.value = tsContent.value

      const { content: sbtContent } = await sbtPromise
      standbyTestimonialContentData.value = sbtContent.value
    }

    function load(id: number) {
      journey.value = JourneyBuilder.build(journeyData.value.find((x) => x.id === id) as Journey)
      console.log('SITE', journey.value)
    }

    return {
      init,
      load,
      journeyData,
      journey,
      tooltipContentData,
      tooltipContent,
      componentContentData,
      componentContent,
      standbyTestimonialContentData,
      standbyTestimonialContent,
      savedPlansCarouselIndex,
      savedPlanTypeSelection,
      applicationsCarouselIndex,
      applicationPlanTypeSelection,
      enrollmentCarouselIndex
    }
  })
}
