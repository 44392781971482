import { defineStore } from 'pinia'
import type { IepRecommendationResponse } from '~/generated/api-clients-generated'
import { IepCurrentCoverageType } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'

export class IepStore {
  static use = defineStore('iep', () => {
    const profile = ProfileStore.use()

    const sync = ref<boolean>(false)

    const currentCoverage = ref<IepCurrentCoverageType | null | undefined>()
    const workingForCoverageProvider = ref<boolean>()
    const employerMoreThan20Employees = ref<boolean>()
    const coverageThroughMarketplace = ref<boolean>()
    const workedAtLeast10Years = ref<boolean>()

    const employerOrSpouseEmployerCoverage = ref<boolean>()

    watch(employerOrSpouseEmployerCoverage, (val) => {
      if (val === true) {
        currentCoverage.value = IepCurrentCoverageType.EmployerOrSpouse
      }
    })

    const recommendation = ref<IepRecommendationResponse | null | undefined>()

    const getRecommendation = async () => {
      const api = PxApi.use().createProfilesClient()

      recommendation.value = await api.getIepRecommendation(iepContext.value)

      return recommendation.value
    }

    const iepContext = computed(() => ({
      birthdate: new Date(profile.dateOfBirth),
      currentCoverage: currentCoverage.value,
      workingForCoverageProvider: workingForCoverageProvider.value,
      employerMoreThan20Employees: employerMoreThan20Employees.value,
      coverageThroughMarketplace: coverageThroughMarketplace.value,
      workedAtLeast10Years: workedAtLeast10Years.value
    }))

    function init(ls: any) {
      ls = _isObject(ls) || _isNil(ls) ? ls : JSON.parse(ls)
      currentCoverage.value = ls?.['currentCoverage']
      workingForCoverageProvider.value = ls?.['workingForCoverageProvider']
      employerMoreThan20Employees.value = ls?.['employerMoreThan20Employees']
      coverageThroughMarketplace.value = ls?.['coverageThroughMarketplace']
      workedAtLeast10Years.value = ls?.['workedAtLeast10Years']
      employerOrSpouseEmployerCoverage.value =
        ls?.['currentCoverage'] === IepCurrentCoverageType.EmployerOrSpouse
          ? true
          : _isNil(ls?.['currentCoverage'])
          ? undefined
          : false

      if (!_isNil(ls?.['recommendation'])) {
        recommendation.value = {
          iepStatus: ls?.['iepStatus'],
          iepEnd: ls?.['iepEnd'],
          iepStart: ls?.['iepStart'],
          recommendation: ls?.['recommendation'],
          attributes: _isString(ls?.['attributes'])
            ? JSON.parse(ls['attributes'])
            : ls?.['attributes'],
          isGep: ls?.['isGep']
        }
      }

      sync.value = true
    }

    async function save() {
      const api = PxApi.use().createProfilesClient()

      const mappedRec = _isNil(recommendation.value)
        ? {}
        : {
            ...recommendation.value,
            attributes: JSON.stringify(recommendation.value?.attributes ?? [])
          }

      await api.updateIepContext(profile.id!, {
        ...iepContext.value,
        ...mappedRec
      })
    }

    const hasRecommendation = computed(() => !_isNil(recommendation.value?.recommendation))

    return {
      currentCoverage,
      workingForCoverageProvider,
      employerMoreThan20Employees,
      coverageThroughMarketplace,
      workedAtLeast10Years,
      employerOrSpouseEmployerCoverage,
      recommendation,
      iepContext,
      getRecommendation,
      hasRecommendation,
      init,
      save,
      sync
    }
  })

  static init(ls: any) {
    const store = IepStore.use()
    store.init(ls)
  }
}
