import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { SessionStore } from '~/stores/session'
import { ProfileStore } from '~/stores/profile'
import { PlanType, WaypointResult } from '~/generated/api-clients-generated'

export class HasPlanTypeRecommendationNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const session = SessionStore.use()
    const profile = ProfileStore.use()
    const { tagEvent } = SessionReplay.use()

    let result: WaypointResult | null = null

    // only one available plan type
    if (session.availablePlanTypes?.length == 1) {
      profile.needsAssessment.planType = session.availablePlanTypes[0]
      session.planType = session.availablePlanTypes[0]

      return (
        this._destinations.get(this.getResultFromPlanType(session.availablePlanTypes[0])) ?? null
      )
    }

    // successfully found rec, more than one available plan type from footprint
    if (session.planTypeRecommendations?.recommendationType) {
      return this._destinations.get(WaypointResult.Next) ?? null
    }

    // no rec, more than one available plan type
    const planTypePriority = [PlanType.MA, PlanType.MAPD, PlanType.PDP, PlanType.GAP, PlanType.MSA]

    for (const p of planTypePriority) {
      if (session.availablePlanTypes.includes(p)) {
        profile.needsAssessment.planType = p
        session.planType = p
        return this._destinations.get(this.getResultFromPlanType(p)) ?? null
      }
    }

    // only way its possible to get to this point is if there's no available plan types
    const payload = {
      response: session.planTypeRecommendations,
      footprint: session.carrierFootprint,
      coveragePreferences: profile.needsAssessment.coveragePreferences
    }
    tagEvent('FailedPlanTypeRecommendation', payload)
    return null
  }

  getResultFromPlanType(planType: PlanType): WaypointResult {
    switch (planType) {
      case PlanType.MAPD:
      case PlanType.MA:
        return WaypointResult.PlanType_MA
      case PlanType.PDP:
        return WaypointResult.PlanType_PDP
      case PlanType.GAP:
        return WaypointResult.PlanType_MS
      case PlanType.MSA:
        return WaypointResult.PlanType_MSA
      default:
        return WaypointResult.Next
    }
  }
}
