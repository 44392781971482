import RedirectNodeBase from '~/models/journey/RedirectNodeBase'

export class SignUpRedirectNode extends RedirectNodeBase {
  async go(): Promise<boolean> {
    const next = this.getNext()

    const { isAuthenticated, signUpRedirect } = Auth.use()

    if (isAuthenticated.value) return false

    await signUpRedirect(next, true)
    return true
  }
}
