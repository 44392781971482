import { SessionStore } from '~/stores/session'
import { createFetch, type FetchContext, type FetchResponse } from 'ofetch'

export class FetchFactory {
  static use() {
    // const cookieJar = CookieJar.use()
    const session = SessionStore.use()

    function setXProfileId(context: FetchContext) {
      if (!_isNil(session.profileId)) {
        context.options.headers = {
          ...context.options.headers,
          'X-Profile-Id': session.profileId
        }
      }
    }

    function setAnalyticsHeaders(context: FetchContext) {
      if (process.client) {
        const { getAnonymousId, getSessionId } = Analytics.use()
        const anonId = getAnonymousId()
        const sessionId = getSessionId()

        let headers = {}

        if (!_isNil(anonId) && !_isNil(sessionId)) {
          headers = {
            'X-Analytics-Id': anonId,
            'X-Analytics-Session': sessionId
          }
        }

        const fbc = useCookie('_fbc').value
        if (!_isNil(fbc)) {
          headers = {
            ...headers,
            'X-Analytics-Fbc': fbc
          }
        }

        const fbp = useCookie('_fbp').value
        if (!_isNil(fbp)) {
          headers = {
            ...headers,
            'X-Analytics-Fbp': fbp
          }
        }

        const campaign = session.params['utm_campaign']
        if (!_isNil(campaign)) {
          headers = {
            ...headers,
            'X-Analytics-Campaign': campaign
          }
        }

        const source = session.params['utm_source']
        if (!_isNil(source)) {
          headers = {
            ...headers,
            'X-Analytics-Source': source
          }
        }

        const medium = session.params['utm_medium']
        if (!_isNil(medium)) {
          headers = {
            ...headers,
            'X-Analytics-Medium': medium
          }
        }

        const term = session.params['utm_term']
        if (!_isNil(term)) {
          headers = {
            ...headers,
            'X-Analytics-Term': term
          }
        }

        const content = session.params['utm_content']
        if (!_isNil(content)) {
          headers = {
            ...headers,
            'X-Analytics-Content': content
          }
        }

        const gclid = session.params['gclid']
        if (!_isNil(gclid)) {
          headers = {
            ...headers,
            'X-Analytics-Gclid': gclid
          }
        }

        context.options.headers = {
          ...context.options.headers,
          ...headers
        }
      }
    }

    const _fetch = createFetch()

    const make$$fetch = (
      onReq?: (ctx: FetchContext) => void,
      onResp?: (ctx: FetchContext & { response: FetchResponse<ResponseType> }) => void
    ) =>
      _fetch.create({
        credentials: 'include' as RequestCredentials,
        onResponseError: function (context: any & { error: Error }): Promise<void> | void {
          console.log('RESPONSE ERROR', context)
        },
        onRequestError(context: any & { error: Error }): Promise<void> | void {
          console.log('REQUEST ERROR', context)
        },
        onRequest(context: FetchContext): Promise<void> | void {
          onReq && onReq(context)
          setXProfileId(context)
          setAnalyticsHeaders(context)
        },
        onResponse(
          context: FetchContext & { response: FetchResponse<ResponseType> }
        ): Promise<void> | void {
          context.options.ignoreResponseError = true
          onResp && onResp(context)
          // cookieJar.touch()
        }
      })

    if (process.client) {
      const cookieJar = CookieJar.use()
      // const { getRequestTracker } = SessionReplay.use()

      const $$fetch = () => {
        // const tracker = getRequestTracker()
        return make$$fetch(
          (_) => {
            // tracker.start()
          },
          (_) => {
            cookieJar.touch()
            // tracker.end(ctx)
          }
        )
      }

      return {
        $$fetch
      }
    } else {
      const $$fetch = () => {
        return make$$fetch((ctx) => {
          ctx.options.headers = useRequestHeaders(['cookie'])
        })
      }

      return {
        $$fetch
      }
    }
  }
}
