import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { ProfileStore } from '~/stores/profile'
import { CoverageType, WaypointResult } from '~/generated/api-clients-generated'

export class SelectedCoverageTypeNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const profile = ProfileStore.use()

    let result: WaypointResult | null = null

    switch (profile.needsAssessment.coverageType) {
      case CoverageType.Healthcare:
        result = WaypointResult.CoverageType_Healthcare
        break
      case CoverageType.PrescriptionDrug:
        result = WaypointResult.CoverageType_PrescriptionDrug
        break
      default:
        result = WaypointResult.CoverageType_Both
    }

    return this._destinations.get(result) ?? null
  }
}
