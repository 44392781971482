import { MsPlanViewModel } from '~/models/quoting/ms/MsPlanViewModel'
import type { Catalog } from '~/types/util'

const PlanA = new MsPlanViewModel({
  planLetter: 'A',
  isHighDeductible: false,
  partADeductiblePct: 1,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const PlanB = new MsPlanViewModel({
  planLetter: 'B',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const PlanC = new MsPlanViewModel({
  planLetter: 'C',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 0,
  partBCoinsurancePct: 0
})

const PlanD = new MsPlanViewModel({
  planLetter: 'D',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const PlanF = new MsPlanViewModel({
  planLetter: 'F',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 0,
  partBCoinsurancePct: 0
})

const PlanG = new MsPlanViewModel({
  planLetter: 'G',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const PlanK = new MsPlanViewModel({
  planLetter: 'K',
  isHighDeductible: false,
  partADeductiblePct: 0.5,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0.1
})

const PlanL = new MsPlanViewModel({
  planLetter: 'L',
  isHighDeductible: false,
  partADeductiblePct: 0.25,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0.05
})

const PlanM = new MsPlanViewModel({
  planLetter: 'M',
  isHighDeductible: false,
  partADeductiblePct: 0.5,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const PlanN = new MsPlanViewModel({
  planLetter: 'N',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const HighDeductiblePlanF = new MsPlanViewModel({
  planLetter: 'FH',
  isHighDeductible: true,
  partADeductiblePct: 0,
  partBDeductiblePct: 0,
  partBCoinsurancePct: 0
})

const HighDeductiblePlanG = new MsPlanViewModel({
  planLetter: 'GH',
  isHighDeductible: true,
  partADeductiblePct: 0,
  partBDeductiblePct: 0,
  partBCoinsurancePct: 0
})

// MASS.
const Core = new MsPlanViewModel({
  planLetter: 'Core',
  isHighDeductible: false,
  partADeductiblePct: 1,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const Supplement1 = new MsPlanViewModel({
  planLetter: 'Supp1',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 0,
  partBCoinsurancePct: 0
})

const Supplement1A = new MsPlanViewModel({
  planLetter: 'Supp1A',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

// WISCONSIN
const WI_Basic = new MsPlanViewModel({
  planLetter: 'WI_B',
  isHighDeductible: false,
  partADeductiblePct: 1,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const WI_BPTBC = new MsPlanViewModel({
  planLetter: 'WI_BPTBC',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const WI_50 = new MsPlanViewModel({
  planLetter: 'WI_50',
  isHighDeductible: false,
  partADeductiblePct: 0.5,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0.1
})

const WI_25 = new MsPlanViewModel({
  planLetter: 'WI_25',
  isHighDeductible: false,
  partADeductiblePct: 0.25,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0.05
})

const WI_HDM = new MsPlanViewModel({
  planLetter: 'WI_HDM',
  isHighDeductible: true,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const WI_HD = new MsPlanViewModel({
  planLetter: 'WI_HD',
  isHighDeductible: true,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

// MINNESOTA
const MN_75 = new MsPlanViewModel({
  planLetter: 'MN_75',
  isHighDeductible: false,
  partADeductiblePct: 0.25,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0.05
})

const MN_50 = new MsPlanViewModel({
  planLetter: 'MN_50',
  isHighDeductible: false,
  partADeductiblePct: 0.5,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0.1
})

const MN_Copayment = new MsPlanViewModel({
  planLetter: 'MN_BPTBC',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const MN_Basic = new MsPlanViewModel({
  planLetter: 'MN_B',
  isHighDeductible: false,
  partADeductiblePct: 1,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const MN_Extended_Basic = new MsPlanViewModel({
  planLetter: 'MN_EBM',
  isHighDeductible: false,
  partADeductiblePct: 0,
  partBDeductiblePct: 1,
  partBCoinsurancePct: 0
})

const MN_HD = new MsPlanViewModel({
  planLetter: 'MN_HD',
  isHighDeductible: true,
  partADeductiblePct: 0,
  partBDeductiblePct: 0,
  partBCoinsurancePct: 0
})

const MN_HDM = new MsPlanViewModel({
  planLetter: 'MN_HDM',
  isHighDeductible: true,
  partADeductiblePct: 0,
  partBDeductiblePct: 0,
  partBCoinsurancePct: 0
})

// const MN_ = new MsPlanViewModel({
//   planLetter: 'MN_',
//   isHighDeductible: false,
//   partADeductiblePct: 0,
//   partBDeductiblePct: 0,
//   partBCoinsurancePct: 0
// })

/* NOTES!!

WISCONSIN: 
  1) Is WI_HDM (High Deductible Plan (w/o Part B Ded. Coverage) the same as High Deductible?

MINNESOTA: 
  1) $20/$50 copayment plans are separate in Wiki but in Picwell they are listed together as MN_CP
  2) Sunfire only returns MN_BTPBC for Copay plan ($20/$50 aren't separate)
  3) Sunfire only returns MN_HDM (High Deductible Plan (w/o Part B Ded. Coverage)), do I use this for "High Deductible" from the Wiki?
  4) Is MN_EBM (Extended Basic Plan w/o Part B Ded. Coverage) the same as just "Extended Basic Plan"?
  5) What do I use for 50% Part A Deductible Plan???
*/

const MsPlanMatrix: Catalog<MsPlanViewModel> = {
  A: PlanA,
  B: PlanB,
  C: PlanC,
  D: PlanD,
  F: PlanF,
  G: PlanG,
  K: PlanK,
  L: PlanL,
  M: PlanM,
  N: PlanN,
  FH: HighDeductiblePlanF,
  GH: HighDeductiblePlanG,

  // Mass.
  Core: Core,
  Supp1A: Supplement1A,
  Supp1: Supplement1,

  //Wisconsin
  WI_B: WI_Basic,
  WI_50: WI_50,
  WI_25: WI_25,
  WI_HDM: WI_HDM,
  WI_HD: WI_HD,
  // Not in wiki
  WI_BPTBC: WI_BPTBC,

  //Minnesota
  MN_B: MN_Basic,
  MN_75: MN_75,
  MN_50: MN_50,
  MN_BPTBC: MN_Copayment,
  MN_EBM: MN_Extended_Basic,
  MN_HDM: MN_HDM,
  MN_HD: MN_HD
}

export default MsPlanMatrix
