import { defineStore } from 'pinia'
import type { Provider } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'

export class ProviderStore {
  static use = defineStore('providers', () => {
    const sync = ref<boolean>(false)

    const profile = ProfileStore.use()
    const providers = ref<Array<Provider>>([])

    // non-persistent
    const staging = ref<Array<Provider>>([])

    function init(ls: Array<Provider> | null = []) {
      providers.value = [...(ls ?? [])]
      sync.value = true
    }

    function stage(provider: Provider) {
      const existing = staging.value.find((x) => x.npi == provider.npi)

      if (existing) {
        existing.locations = provider.locations
      } else {
        staging.value.unshift(provider)
      }
    }

    async function save(provider: Provider) {
      const api = PxApi.use().createProfilesClient()

      const uuid = await api.saveProvider(profile.id!, provider)

      const existing = providers.value.find((x) => x.npi == provider.npi)

      if (existing) {
        existing.locations = provider.locations
      } else {
        provider.uuid = uuid
        providers.value.unshift(provider)
      }
    }

    async function remove(npi: string) {
      const api = PxApi.use().createProfilesClient()

      const existing = providers.value.findIndex((x) => x.npi == npi)

      if (existing > -1) {
        await api.deleteProvider(profile.id!, npi)
      } else {
        console.error(
          'Tried to remove provider that does not exist in the store. If this happens something is very wrong.'
        )
        return
      }

      providers.value.splice(existing, 1)
    }

    function removeStaged(npi: string) {
      const existing = staging.value.findIndex((x) => x.npi == npi)
      if (existing > -1) {
        staging.value.splice(existing, 1)
      }
    }

    return {
      sync,
      providers,
      staging,
      init,
      save,
      remove,
      removeStaged,
      stage
    }
  })

  static init(ls: Array<Provider> | null = []) {
    const store = ProviderStore.use()
    store.init(ls)
  }
}
