import WaypointNodeBase from '~/models/journey/WaypointNodeBase'
import type { IDecisionNode, WaypointNode } from '~/models/journey/waypoints'
import { WaypointResult, WaypointType } from '~/generated/api-clients-generated'
import type { Path } from '~/types/util'

export default abstract class DecisionNodeBase extends WaypointNodeBase implements IDecisionNode {
  type: WaypointType.Decision = WaypointType.Decision

  abstract getNext(): WaypointNode | Path | null

  yesNo(value: boolean) {
    return value ? this.yes : this.no
  }

  get yes() {
    return this._destinations.get(WaypointResult.Yes) ?? null
  }

  get no() {
    return this._destinations.get(WaypointResult.No) ?? null
  }
}
