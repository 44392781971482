import type { PlanBenefit, PlanDetail } from '~/generated/api-clients-generated'
import { BenefitCategory, BenefitSection } from '~/generated/api-clients-generated'

export class YourBenefit {
  private readonly primaryCategoryId: BenefitCategory
  readonly associatedBenefits: Array<PlanBenefit>

  constructor(benefits: Array<PlanBenefit>, primaryCategoryId: BenefitCategory) {
    this.primaryCategoryId = primaryCategoryId
    this.associatedBenefits = benefits
  }

  get primaryBenefit() {
    return this.associatedBenefits.find((x) => x.categoryId === this.primaryCategoryId)
  }

  get supplementalBenefits() {
    return this.associatedBenefits.filter((x) => x.categoryId !== this.primaryCategoryId)
  }
}

export class BenefitDetailsVm {
  readonly visionBenefit: YourBenefit | null
  readonly hearingBenefit: YourBenefit | null
  readonly dentalBenefit: YourBenefit | null
  readonly fitnessBenefit: YourBenefit | null
  readonly otcBenefit: YourBenefit | null
  readonly transportationBenefit: YourBenefit | null
  readonly travelBenefit: YourBenefit | null

  constructor(plan: PlanDetail) {
    const benefits = plan.curatedBenefits?.flatMap((x) => x.benefits ?? [])
    this.visionBenefit = new YourBenefit(
      plan?.curatedBenefits?.find((x) => x.sectionId === BenefitSection.Vision)?.benefits ?? [],
      BenefitCategory.VisionServices
    )

    this.hearingBenefit = new YourBenefit(
      plan?.curatedBenefits?.find((x) => x.sectionId === BenefitSection.Hearing)?.benefits ?? [],
      BenefitCategory.HearingServices
    )

    this.dentalBenefit = new YourBenefit(
      plan?.curatedBenefits?.find((x) => x.sectionId === BenefitSection.Dental)?.benefits ?? [],
      BenefitCategory.DentalServices
    )

    this.fitnessBenefit = new YourBenefit(
      benefits?.filter((x) => x.categoryId === BenefitCategory.FitnessBenefit) ?? [],
      BenefitCategory.FitnessBenefit
    )

    this.otcBenefit = new YourBenefit(
      benefits?.filter((x) => x.categoryId === BenefitCategory.OverTheCounterItems) ?? [],
      BenefitCategory.OverTheCounterItems
    )

    this.transportationBenefit = new YourBenefit(
      benefits?.filter((x) => x.categoryId === BenefitCategory.Transportation) ?? [],
      BenefitCategory.Transportation
    )

    this.travelBenefit = new YourBenefit(
      [
        {
          categoryId: BenefitCategory.Custom,
          description: plan.selectedBenefits?.DomesticTravel ? 'Covered' : 'Not Covered'
        }
      ],
      BenefitCategory.Custom
    )
  }
}
