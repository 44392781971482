import RedirectNodeBase from '~/models/journey/RedirectNodeBase'
import { ApplicationStore } from '~/stores/applications'

export class ExternalCarrierAppRedirectNode extends RedirectNodeBase {
  async go(): Promise<boolean> {
    const apps = ApplicationStore.use()

    const { back } = AppRouter.use()

    if (!apps.currentApp) {
      console.error('failed to get current app')
      return false
    }

    const url = apps.currentApp.engineData?.plans[0].externalAppUrl

    if (!url) {
      console.error('could not find external app url')
      return false
    }

    window.location.replace(url)
    // back()
    return true
  }
}
