import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { SessionStore } from '~/stores/session'
import { IepStore } from '~/stores/iep'
import { ProfileStore } from '~/stores/profile'
import { IepRecommendation, IepStatus } from '~/generated/api-clients-generated'

export class CompleteIepEditsNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    const session = SessionStore.use()
    const iep = IepStore.use()
    const profile = ProfileStore.use()

    const { track } = Analytics.use()

    const newAnswers = session.modifiedIepContext

    iep.currentCoverage = newAnswers.currentCoverage
    iep.workingForCoverageProvider = newAnswers.workingForCoverageProvider
    iep.employerMoreThan20Employees = newAnswers.employerMoreThan20Employees
    iep.coverageThroughMarketplace = newAnswers.coverageThroughMarketplace
    iep.workedAtLeast10Years = newAnswers.workedAtLeast10Years

    profile.dateOfBirth = parseDate(newAnswers.birthdate).toDate()

    await iep.getRecommendation()
    await iep.save()

    track('IEPRecommendation', {
      ...iep.recommendation,
      recommendation: IepRecommendation[iep.recommendation?.recommendation],
      iepStatus: IepStatus[iep.recommendation?.iepStatus]
    })

    session.editingIepContext = false
    session.modifiedIepContext = {}
  }
}
