import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { ProfileStore } from '~/stores/profile'

export class ShowTobaccoQuestionNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    const profile = ProfileStore.use()

    const excludeStates = flag<Array<string>>('tobacco-use-exclude-states', []).value

    return this.yesNo(!excludeStates.includes(profile.location?.stateCode ?? '') && (profile.isTobaccoUser === null))
  }
}
