import { Messages } from '@openreplay/tracker'
import type { FetchContext, FetchResponse } from 'ofetch/dist/node'

export class SessionReplay {
  static use() {
    const { $openReplay } = useNuxtApp()

    const app = $openReplay.use((app) => app)

    const getRequestTracker = () => {
      let startTime = 0
      const start = () => (startTime = performance.now())

      const end = (context: FetchContext & { response: FetchResponse<ResponseType> }) => {
        if (!isActive() || _isNil(app)) return

        const duration = performance.now() - startTime

        const reqHeaders: Record<string, string> = {}
        const resHeaders: Record<string, string> = {}

        if (context.options?.headers instanceof Headers) {
          context.options?.headers.forEach((value, key) => (reqHeaders[key] = value))
        } else if (_isArray(context.options?.headers)) {
          context.options.headers.forEach(([key, value]) => (reqHeaders[key] = value))
        } else if (_isObject(context.options?.headers)) {
          Object.entries(context.options.headers).forEach(
            ([key, value]) => (reqHeaders[key] = value)
          )
        }

        context.response.headers.forEach((value, key) => (resHeaders[key] = value))

        const request = context.request as RequestInfo

        const url = typeof request === 'string' ? request : request.url
        const requestJson = context.options.body ?? ''

        const responseJson = JSON.stringify(context.response._data)

        const sessionInfo = app.getSessionInfo()
        console.log('TIMESTAMP', sessionInfo.timestamp, performance.timeOrigin)

        app.send(
          Messages.Fetch(
            context.options.method ?? 'GET',
            url,
            JSON.stringify({
              headers: reqHeaders,
              body: requestJson
            }),
            JSON.stringify({
              headers: resHeaders,
              body: responseJson
            }),
            context.response.status,
            startTime + (sessionInfo.timestamp || performance.timeOrigin),
            duration
          )
        )
      }

      return {
        start,
        end
      }
    }

    const identify = (id: string) => $openReplay.setUserID(id)
    const tagEvent = (key: string, payload?: any) => $openReplay.event(key, payload)
    const tagIssue = (key: string, payload?: any) => $openReplay.issue(key, payload)
    const getSessionId = () => $openReplay.getSessionID()
    const getSessionUrl = (withCurrentTime?: boolean) =>
      $openReplay.getSessionURL({ withCurrentTime })

    const isActive = () => $openReplay.isActive()
    const start = (id?: string | null) =>
      $openReplay.start({
        userID: id ?? undefined
      })

    return {
      identify,
      tagEvent,
      tagIssue,
      getSessionId,
      getSessionUrl,
      isActive,
      start,
      getRequestTracker
    }
  }
}
