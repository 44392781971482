import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { ProfileStore } from '~/stores/profile'

export class ShowBirthdateQuestionNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const profile = ProfileStore.use()
    return this.yesNo(!profile.dateOfBirth)
  }
}
