import { useBreakpoints } from '@vueuse/core'

export class Breakpoints {
  static use() {
    const breakpoints = useBreakpoints({
      '<xs': '0px',
      xs: '414px',
      sm: '600px',
      md: '768px',
      lg: '1024px',
      xl: '1200px',
      '2xl': '1440px'
    })

    const isMobile = breakpoints.smallerOrEqual('sm')
    const isTablet = breakpoints.between('sm', 'lg')
    const isDesktop = breakpoints.greaterOrEqual('lg')

    return {
      isMobile,
      isTablet,
      isDesktop,
      breakpoints
    }
  }
}
