import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'

export class SkipSpecialistRefTest extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    //TODO
    return this.no
  }
}
