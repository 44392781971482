import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { WaypointResult } from '~/generated/api-clients-generated'

export class BrowsePostStandbyVariationNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    var variation = flag('post-standby-variation-browse', '').value

    let result: WaypointResult | null = null

    switch (variation) {
      case 'quotes':
        result = WaypointResult.PostStandby_Quotes
        break
      case 'limited-choice':
        result = WaypointResult.PostStandby_LimitedChoice
        break
      case 'plan-finder':
        result = WaypointResult.PostStandby_Quotes
        break
      default:
        result = WaypointResult.PostStandby_Quotes
    }

    return this._destinations.get(result) ?? null
  }
}
