export function percentile(sample: number[], percentile: number) {
  const sorted = _sortBy(sample)
  const index = Math.floor(sorted.length * (percentile / 100))

  return sorted[index - 1]
}

export function percentileOf(sample: number[], value: number) {
  const sorted = _sortBy(sample)
  const index = sorted.findIndex((el) => el >= value)

  if (index === -1) {
    return 100
  }

  return (index / (sample.length - 1)) * 100
}

export function linearProjection(
  x: number,
  minX: number,
  maxX: number,
  minProjection: number,
  maxProjection: number
) {
  return (x - minX) * ((maxProjection - minProjection) / (maxX - minX)) + minProjection
}
