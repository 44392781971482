import { defineStore } from 'pinia'
import type { Application } from '~/generated/api-clients-generated'
import type { Guid } from '~/types/util'
import { SessionStore } from '~/stores/session'

export class ApplicationStore {
  static use = defineStore('applications', () => {
    const sync = ref<boolean>(false)

    const applications = ref<Application[]>([])

    const { tagEvent } = SessionReplay.use()

    function init(apps: Application[] | null | undefined) {
      applications.value = apps ?? []
      sync.value = true
    }

    const currentApp = computed(() => {
      const session = SessionStore.use()
      return applications.value?.find((a) => a.id === session.currentApplicationId)
    })

    async function saveAnswers(answers: Record<string, any>) {
      currentApp.value!.answers = answers

      const { createApplicationsClient } = PxApi.use()
      const client = createApplicationsClient()

      await client.saveApplication({
        applicationId: currentApp.value!.id!,
        answers: answers
      })
    }

    async function submitApplication() {
      const { createApplicationsClient } = PxApi.use()
      const client = createApplicationsClient()

      // skipping this call for now as it is only needed if we ever bring back digital medsupp
      // const rxStore = RxStore.use()
      // currentApp.value!.answers['prescribed_medications'] = rxStore.rxs
      //
      // await saveAnswers(currentApp!.value.answers)

      const resp = await client.submitApplication({
        applicationId: currentApp.value!.id ?? undefined,
        requestedEffectiveDate: currentApp.value!.answers?.['requestedEffectiveDate'],
        timeZoneId: currentApp.value!.answers?.['homeAddress']?.timeZoneId,
        utcOffset: currentApp.value!.answers?.['homeAddress']?.rawOffset
      })

      currentApp.value!.status = 'submitted'
      currentApp.value!.enrollmentCode = resp.confirmationCode

      tagEvent('ApplicationSubmitted', {
        applicationId: currentApp.value!.id,
        planId: currentApp.value!.medicareId
      })
    }

    async function cancelApplication(applicationId: Guid) {
      const { createApplicationsClient } = PxApi.use()
      const appsClient = createApplicationsClient()

      await appsClient.cancelApplication(applicationId)

      const localApp = applications.value.find((a) => a.id === applicationId)

      if (localApp) {
        applications.value = applications.value.filter((x) => x.id != localApp.id)
      }
    }

    return {
      sync,
      init,
      applications,
      currentApp,
      saveAnswers,
      submitApplication,
      cancelApplication
    }
  })

  static init(apps?: Application[] | null | undefined) {
    const store = ApplicationStore.use()

    store.init(apps)
  }
}
