import { PlanType, PlanTypeRecommendationType } from '~/generated/api-clients-generated'

export function isPackageRecommendation(typeRec?: PlanTypeRecommendationType | null) {
  const packageTypes = [PlanTypeRecommendationType.MSA_PDP, PlanTypeRecommendationType.MS_PDP]

  return !!typeRec && packageTypes.includes(typeRec)
}

export function getPlanTypeDisplay(planType?: PlanType | null) {
  switch (planType) {
    case PlanType.MAPD:
      return 'Medicare Advantage Plan'
    case PlanType.PDP:
      return 'Prescription Drug Plan'
    case PlanType.GAP:
      return 'Medicare Supplement Insurance'
    case PlanType.TM:
      return 'Traditional Medicare'
    case PlanType.MSA:
      return 'Medical Savings Account'
    case undefined:
      return ''
  }
}
