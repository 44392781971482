import { default as _91_46_46_46path_937tscjZcrwAMeta } from "/app/pages/[...path].vue?macro=true";
import { default as _91_91pageId_93_93sYfMqV68MUMeta } from "/app/pages/apply/[[pageId]].vue?macro=true";
import { default as applywndI893Te2Meta } from "/app/pages/apply.vue?macro=true";
export default [
  {
    name: _91_46_46_46path_937tscjZcrwAMeta?.name ?? "path",
    path: _91_46_46_46path_937tscjZcrwAMeta?.path ?? "/:path(.*)*",
    meta: _91_46_46_46path_937tscjZcrwAMeta || {},
    alias: _91_46_46_46path_937tscjZcrwAMeta?.alias || [],
    redirect: _91_46_46_46path_937tscjZcrwAMeta?.redirect,
    component: () => import("/app/pages/[...path].vue").then(m => m.default || m)
  },
  {
    name: applywndI893Te2Meta?.name ?? "apply",
    path: applywndI893Te2Meta?.path ?? "/apply",
    meta: applywndI893Te2Meta || {},
    alias: applywndI893Te2Meta?.alias || [],
    redirect: applywndI893Te2Meta?.redirect,
    component: () => import("/app/pages/apply.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91pageId_93_93sYfMqV68MUMeta?.name ?? "apply-pageId",
    path: _91_91pageId_93_93sYfMqV68MUMeta?.path ?? ":pageId?",
    meta: _91_91pageId_93_93sYfMqV68MUMeta || {},
    alias: _91_91pageId_93_93sYfMqV68MUMeta?.alias || [],
    redirect: _91_91pageId_93_93sYfMqV68MUMeta?.redirect,
    component: () => import("/app/pages/apply/[[pageId]].vue").then(m => m.default || m)
  }
]
  }
]