import OpenReplay from '@openreplay/tracker'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()

  const tracker = new OpenReplay({
    projectKey: runtimeConfig.public.openReplayProjectKey,
    ingestPoint: runtimeConfig.public.openReplayIngestUrl,
    respectDoNotTrack: false,
    obscureInputDates: false,
    obscureTextNumbers: false,
    obscureTextEmails: false,
    obscureInputEmails: false,
    obscureInputNumbers: false,
    defaultInputMode: 0,
    network: {
      failuresOnly: false,
      ignoreHeaders: false,
      capturePayload: true,
      sessionTokenHeader: false,
      captureInIframes: true
    },
    __DISABLE_SECURE_MODE: process.dev
  })

  return {
    provide: {
      openReplay: tracker
    }
  }
})
