import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import undefined from '~/components/page/undefined.vue'
import { ProfileStore } from '~/stores/profile'
import { CoverageGoal } from '~/generated/api-clients-generated'

export class SetDefaultGoalsNode extends ProcessingNodeBase {
  run(): Promise<void> {
    const profile = ProfileStore.use()
    profile.needsAssessment.coverageGoals = [
      CoverageGoal.LowMedicalCost,
      CoverageGoal.LowDrugCost,
      CoverageGoal.SeeMyDoctors,
      CoverageGoal.ExtraCoverage
    ]

    return Promise.resolve()
  }
}
