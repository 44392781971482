<template>
  <component :is="icon" class="shrink-0" role="presentation" />
</template>

<script setup lang="ts">
  const props = defineProps({
    name: String
  })

  const icon = defineAsyncComponent(() => import(`../../assets/icons/${props.name}.svg?component`))
</script>
