import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { WaypointResult } from '~/generated/api-clients-generated'

export class CoveragePrefsTestNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    console.log('COVERAGE PREFS')
    const { flag } = FeatureFlags.use()

    const value = flag('coverage-preferences-variation', 'expanded').value
    const result =
      value === 'compact'
        ? WaypointResult.CoveragePrefsTest_Compact
        : WaypointResult.CoveragePrefsTest_Expanded

    return this._destinations.get(result) ?? null
  }
}
