import RedirectNodeBase from '~/models/journey/RedirectNodeBase'
import { EngineStore } from '~/stores/engine'
import { ProfileStore } from '~/stores/profile'
import type { EnrollRequest } from '~/generated/api-clients-generated'
import { ApplicationStore } from '~/stores/applications'
import { SessionStore } from '~/stores/session'

export class ConnectureRedirectNode extends RedirectNodeBase {
  async go(): Promise<boolean> {
    const appStore = ApplicationStore.use()
    const engine = EngineStore.use()
    const profile = ProfileStore.use()

    const { back } = AppRouter.use()

    const { createEnrollmentClient } = PxApi.use()
    const client = createEnrollmentClient()

    const currentApp = appStore.currentApp
    if (!currentApp) {
      console.error('failed to get current app')
      return false
    }

    const session = SessionStore.use()

    const request: EnrollRequest = {
      applicationId: currentApp?.id!,
      engineParams: engine.params,
      planId: currentApp.planExternalId!,
      title: '',
      firstName: profile.firstName!,
      lastName: profile.lastName!,
      middleInitial: profile.middleName,
      phoneNumber: profile.mobilePhone?.number,
      email: profile.emailAddress?.address,
      mbi: profile.medicareCard?.medicareNumber,
      profileId: profile.id!
    }

    const response = await client.start(request)

    console.log('ConnectureRedirectNode start response ', response)

    const url = `${response.url}?${response.parameters}`
    console.log('URL: ', url)

    window.location.replace(url)

    // back()

    return true
  }
}
