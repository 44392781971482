import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { SiteStore } from '~/stores/site'

export class CanChooseYourOwnAdventureNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const site = SiteStore.use()
    const { flag } = FeatureFlags.use()
    const config = flag<any>('cyoa-by-journey', {}).value
    const enable = config[site.journey!.id!.toString()]

    return this.yesNo(enable)
  }
}
