import type { IPageNode } from '~/models/journey/waypoints'
import { SiteStore } from '~/stores/site'
import { normalizePath } from '~/utils/path'

export class Waypoint {
  static use() {
    const site = SiteStore.use()

    const current = computed(() => {
      const { path } = AppRoute.use()
      return resolveWaypoint(path.value)
    })

    function get(path: string) {
      return resolveWaypoint(normalizePath(path))
    }

    function resolveWaypoint(path: string): IPageNode | undefined {
      if (_isEmpty(path)) return undefined

      const w = site.journey.catalog[path]
      if (!!w) return w as IPageNode

      const wc = site.journey.catalog[`${normalizePath(path)}/*`]
      if (!!wc) return wc as IPageNode

      return resolveWaypoint(path.split('/').slice(0, -1).join('/'))
    }

    return {
      current,
      get
    }
  }
}
