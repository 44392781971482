import { WaypointResult } from '~/generated/api-clients-generated'
import type { IPageNode, WaypointNode } from '~/models/journey/waypoints'
import {
  isDecisionNode,
  isPageNode,
  isProcessingNode,
  isRedirectNode
} from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { Waypoint } from './Waypoint'
import { SessionStore } from '~/stores/session'
import { ProfileStore } from '~/stores/profile'

export class WaypointEngine {
  static get props() {
    return {
      onArrive: Function as PropType<(() => void) | (() => Promise<void>)>,
      onBeforeDepart: {
        type: Function as PropType<(() => boolean) | (() => Promise<boolean>)>,
        default: () => true
      },
      onBeforeSkip: {
        type: Function as PropType<(() => boolean) | (() => Promise<boolean>)>,
        default: () => true
      },
      getResult: {
        type: Function as PropType<() => WaypointResult>,
        default: () => WaypointResult.Next
      },
      getQueryParams: {
        type: Function as PropType<(() => Object) | (() => Promise<Object>)>,
        default: () => ({})
      }
    }
  }

  static get defaultProps() {
    return {
      onArrive: () => undefined,
      onBeforeDepart: () => true,
      onBeforeSkip: () => true,
      getResult: () => WaypointResult.Next,
      getQueryParams: () => ({})
    }
  }

  static use(props: any = this.defaultProps) {
    const { push, replace } = AppRouter.use()

    const ready: Ref<boolean> = ref<boolean>(false)
    const departing: Ref<boolean> = ref<boolean>(false)

    const { current: waypoint } = Waypoint.use()

    function next(): WaypointNode | Path | null {
      const result = props.getResult()
      console.log('RESULT', result)
      return waypoint.value!.getNext(result) ?? null
    }

    async function arrive() {
      if (props.onArrive) await props.onArrive()
    }

    async function beforeDepart() {
      return await props.onBeforeDepart()
    }

    async function beforeSkip() {
      return await props.onBeforeSkip()
    }

    async function queryParams() {
      return await props.getQueryParams()
    }

    async function depart(navOverride: (() => void) | (() => Promise<void>) | null = null) {
      console.log('DEPART CALLED')

      if (departing.value) return

      departing.value = true

      try {
        if (!(await beforeDepart())) {
          departing.value = false
          return
        }

        // SessionStore.save()
        await ProfileStore.save()
        SiteFilingCode.setFilingCode()

        await (navOverride ?? navigate)()
      } catch (ex) {
        // TODO: log to Sentry
        console.log('ERROR IN DEPART TRY BLOCK: ', ex)
        departing.value = false
      }
    }

    async function skip(replaceRoute?: boolean) {
      if (departing.value) return

      departing.value = true

      try {
        if (!(await beforeSkip())) {
          departing.value = false
          return
        }

        await navigate(replaceRoute)
      } catch (ex) {
        // TODO: log to Sentry
        departing.value = false
      }
    }

    async function navigate(replaceRoute?: boolean) {
      let node = next()
      console.log('NODE', node)

      let destination: Path | null = null

      if (typeof node === 'string') {
        destination = node
      } else {
        while (
          !_isNil(node) &&
          (isDecisionNode(node) || isProcessingNode(node) || isRedirectNode(node))
        ) {
          if (isProcessingNode(node)) {
            await node.run()
          }

          if (isRedirectNode(node)) {
            const redirected = await node.go()
            if (redirected) return
          }

          node = node.getNext()
        }
      }

      if (_isNil(node)) return

      if (typeof node === 'string') {
        destination = node
      } else if (isPageNode(node)) {
        destination = node.path
      }

      if (_isNil(destination)) return

      const params = await queryParams()
      const location = { path: destination, query: params }

      if (replaceRoute === true || waypoint.value?.replace) {
        await replace(location)
      } else {
        await push(location)
      }
    }

    return {
      ready,
      departing,
      depart,
      skip,
      next
    }
  }
}
