import { ProfileStore } from '~/stores/profile'
import { EngineStore } from '~/stores/engine'
import {
  AdditionalBenefitType,
  CostFactor,
  CoveragePreference,
  PolicyType,
  SnpType
} from '~/generated/api-clients-generated'
import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export default class PlanTraits {
  static use(quotes: QuotedPlanVm[]) {
    const profile = ProfileStore.use()
    const engine = EngineStore.use()

    const userPreReqs: Record<string, boolean> = {
      hasNoPremium: true,
      allDrugsCovered: !!engine.params.rxs?.length && engine.params.rxs.length > 0,
      mostDrugsCovered: !!engine.params.rxs?.length && engine.params.rxs.length > 0,
      pharmacyPreferredPricing: !!engine.params.pharmacy,
      allDoctorsCovered: !!engine.params.doctors?.length && engine.params.doctors.length > 0,
      mostDoctorsCovered: !!engine.params.doctors?.length && engine.params.doctors.length > 0,
      mostBenefitsCovered:
        !!engine.params.additionalBenefits?.length && engine.params.additionalBenefits.length > 0,
      travelCoverage: true,
      doctorFlexibility: true,
      isDsnp: !!profile.medicaidInfo.hasMedicaid,
      lowOutOfPocket: true
    }

    const top25OopValue = percentile(
      _compact(
        quotes
          .filter((q) => q.type === engine.selectedPlanType)
          .map((q) => q.recommendation?.medicalCost)
      ),
      75
    )

    const planTests = {
      allDoctorsCovered(quote: QuotedPlanVm) {
        return quote.providerCoverage.allInNetwork
      },
      mostDoctorsCovered(quote: QuotedPlanVm) {
        return (
          !quote.providerCoverage.allInNetwork &&
          quote.providerCoverage.inNetworkCount / quote.providerCoverage.count >= 0.75
        )
      },
      allDrugsCovered(quote: QuotedPlanVm) {
        return quote.drugCoverage.allCovered
      },
      mostDrugsCovered(quote: QuotedPlanVm) {
        return (
          !quote.drugCoverage.allCovered &&
          quote.drugCoverage.coveredCount / quote.drugCoverage.count >= 0.75
        )
      },
      pharmacyPreferredPricing(quote: QuotedPlanVm) {
        return !!quote.pharmacyCoverage.preferred
      },
      mostBenefitsCovered(quote: QuotedPlanVm) {
        return quote.additionalBenefits.coveredCount / quote.additionalBenefits.count >= 0.75
      },
      travelCoverage(quote: QuotedPlanVm) {
        return (
          quote.policyType === PolicyType.PPO ||
          quote.additionalBenefits.covered.some((b) => b.name === 'DomesticTravel')
        )
      },
      doctorFlexibility(quote: QuotedPlanVm) {
        return (
          quote.policyType === PolicyType.PPO ||
          quote.policyType === PolicyType.LPPO ||
          quote.policyType === PolicyType.RPPO
        )
      },
      hasNoPremium(quote: QuotedPlanVm) {
        return quote.details.premium === 0
      },
      isDsnp(quote: QuotedPlanVm) {
        return quote.snpType === SnpType.DualEligible
      },
      lowOutOfPocket(quote: QuotedPlanVm) {
        return (
          !_isNil(quote.recommendation.medicalCost) &&
          quote.recommendation.medicalCost >= top25OopValue
        )
      }
    }

    return quotes.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.medicareId]: _mapValues(planTests, (value, key) => ({
          applicable: value(curr),
          display: userPreReqs[key]
        }))
      }),
      {}
    )
  }
}
