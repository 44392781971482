import { SessionStore } from '~/stores/session'
import { ProfileStore } from '~/stores/profile'
import { EngineStore } from '~/stores/engine'
import { CarrierStore } from '~/stores/carriers'
import dayjs from 'dayjs'
import { StoreWatchers } from '~/composables/StoreWatchers'

export class StoreInit {
  static async use() {
    await SessionStore.load()

    StoreWatchers.use()

    SessionStore.autosave()
    ProfileStore.trackChanges()
  }
}
