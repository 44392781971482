import dayjs from 'dayjs'

export class DefaultDates {
  static use(year?: number) {
    const startDate = computed(() => {
      if (!year || year === dayjs().year()) {
        return dayjs().add(1, 'month').startOf('month')
      }

      return jan1(year)
    })
    const annualizedStartDate = computed(() => jan1(startDate.value.year()))
    const dateOfBirth = computed(() => startDate.value.subtract(65, 'year'))
    const planYear = computed(() => startDate.value.year())

    return {
      startDate,
      annualizedStartDate,
      dateOfBirth,
      planYear
    }
  }
}
