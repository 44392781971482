import { PlanType } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'
import { SessionStore } from '~/stores/session'
import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export class SiteFilingCode {
  static filingCode = ref<string>('')
  static supplementaryFilingCode = ref<string>('')

  static use(
    planType: PlanType | null = null,
    carrierKey: string | undefined | null = null,
    pageApply: boolean | undefined = false,
    planYear: number | undefined | null = null
  ) {
    const profile = ProfileStore.use()
    const session = SessionStore.use()

    const defaultPage = '2023-099'
    const defaultMultiplan = 'MULTIPLAN_HPT2024-049_M'
    const defaultMs = '2022-027'

    const currentFilingCode = computed(() => {
      if (!planType && !carrierKey && !pageApply) return defaultPage
      switch (planType) {
        case PlanType.GAP:
          return msFilingCode.value
        default:
          return pageApply ? mapdAppFilingCode.value : defaultMultiplan
      }
    })

    const currentSupplementaryFilingCode = computed(() => {
      const anthemMsStates = ['AZ', 'CA', 'CO', 'KY', 'MO', 'NV', 'NH', 'NY', 'TX', 'VA']
      const anthemSuppCode =
        anthemMsStates.includes(profile.location?.stateCode!) &&
        currentFilingCode.value !== defaultMs
          ? defaultMs
          : ''

      const aetnaSuppCode =
        profile.location?.stateCode && profile.location?.stateCode === 'TX' ? `2022-027_TX` : ''

      return `${anthemSuppCode} ${aetnaSuppCode}`
    })

    const msFilingCode = computed(() => {
      switch (carrierKey!) {
        case undefined:
          if (profile.location?.stateCode && profile.location.stateCode === 'TX') {
            return `${defaultMs}_TX`
          }
          break
        case 'cigna':
          const cignaMsStates = ['FL', 'TX']
          if (
            session.existsInFootprint('cigna', PlanType.GAP) &&
            cignaMsStates.includes(profile.location?.stateCode ?? '')
          ) {
            return `${defaultMs}_${profile.location.stateCode}`
          } else {
            return defaultMs
          }
        default:
          return profile.location?.stateCode && profile.location.stateCode === 'TX'
            ? `${defaultMs}_TX`
            : defaultMs
      }
    })

    const mapdAppFilingCode = computed(() => {
      if (planYear === 2024) {
        return 'MULTIPLAN_HPT2024-049_M'
      }

      switch (carrierKey!) {
        case 'cigna':
        case 'floridablue':
        case 'highmark':
        case 'upmc':
        case 'uhc':
          return 'MULTIPLAN_HPT2022-132_M'
        case 'aetna':
        case 'anthem':
        case 'hcsc':
        case 'humana':
        case 'wellcare':
          return 'MULTIPLAN_HPT2022-059c_M'
        case 'zing':
        case 'medigold':
        case 'ibx':
          return 'MULTIPLAN_HPT2023-037_M'
        default:
          return defaultMultiplan
      }
    })
    return {
      currentFilingCode,
      currentSupplementaryFilingCode
    }
  }
  static setFilingCode(
    planType: PlanType | null = null,
    carrierKey: string | undefined | null = null,
    pageApply: boolean | undefined = false,
    planYear: number | null | undefined = null
  ) {
    const { currentFilingCode, currentSupplementaryFilingCode } = SiteFilingCode.use(
      planType,
      carrierKey,
      pageApply,
      planYear
    )
    this.filingCode.value = currentFilingCode.value
    this.supplementaryFilingCode.value = currentSupplementaryFilingCode.value
  }
  static setFilingFromDispQuotes(displayedQuotes: QuotedPlanVm[]) {
    const planType: PlanType | null = displayedQuotes?.[0]?.type ?? null
    let carrierKey: string | null = null
    if (displayedQuotes.find((x) => x.details.carrierKey == 'cigna')) carrierKey = 'cigna'
    const { currentFilingCode, currentSupplementaryFilingCode } = SiteFilingCode.use(
      planType,
      carrierKey,
      false
    )
    this.filingCode.value = currentFilingCode.value
    this.supplementaryFilingCode.value = currentSupplementaryFilingCode.value
  }

  static clearFilingCode() {
    this.filingCode.value = ''
    this.supplementaryFilingCode.value = ''
  }
}
