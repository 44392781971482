import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { SessionStore } from '~/stores/session'
import { WaypointResult } from '~/generated/api-clients-generated'

export class RouteIepResultNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const session = SessionStore.use()

    return session.editingIepContext
      ? this._destinations.get(WaypointResult.Iep_EditsComplete) ?? null
      : this._destinations.get(WaypointResult.Iep_ShowRecommendation) ?? null
  }
}
