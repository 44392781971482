import RedirectNodeBase from '~/models/journey/RedirectNodeBase'

export class SignInRedirectNode extends RedirectNodeBase {
  async go(): Promise<boolean> {
    const next = this.getNext()

    const { isAuthenticated, signInRedirect } = Auth.use()

    if (isAuthenticated.value) return false

    await signInRedirect(next, true)
    return true
  }
}
