import { MutationType } from 'pinia'
import type { Store } from 'pinia'
import { SessionStore } from '~/stores/session'
import { ProfileStore } from '~/stores/profile'
import { RxStore } from '~/stores/rx'
import { ProviderStore } from '~/stores/provider'
import { PharmacyStore } from '~/stores/pharmacy'
import { EngineStore } from '~/stores/engine'
import { ApplicationStore } from '~/stores/applications'

export class TabSync {
  static use() {
    if (process.client) {
      const tabId = guid()

      const session = SessionStore.use()
      StoreSync.use(tabId, session)

      const profile = ProfileStore.use()
      StoreSync.use(tabId, profile)

      const rx = RxStore.use()
      StoreSync.use(tabId, rx)

      const provider = ProviderStore.use()
      StoreSync.use(tabId, provider)

      const pharmacy = PharmacyStore.use()
      StoreSync.use(tabId, pharmacy)

      const applications = ApplicationStore.use()
      StoreSync.use(tabId, applications)

      const engine = EngineStore.use()
      StoreSync.use(tabId, engine, (_) => true, EngineStore.syncSerializer)

      console.info('tab initialized', tabId)
    }
  }
}

export type StoreSyncData = {
  source: string
  state: any
}

export type StoreSyncSerializer = {
  serialize: (data: StoreSyncData) => string
  deserialize: (json: string) => StoreSyncData
}

const DefaultStoreSyncSerializer: StoreSyncSerializer = {
  serialize: (data: StoreSyncData) => JSON.stringify(data),
  deserialize: (json: string) => JSON.parse(json) as StoreSyncData
}

/*
 * DO NOT EXPORT THIS CLASS
 * Accidentally syncing a store more than once with a different tab ID will have unpleasant consequences and should
 * therefore only be managed in this file.  Talk to me (Bryan) if you think you have a legitimate case for using
 * this class somewhere else, and I'll tell you why you're wrong, or - in the unlikely event that you are not - we
 * can work toward some useful-but-safe solution.
 */
class StoreSync {
  static use(
    selfId: string,
    store: Store,
    filter: (state: any) => boolean = (state) => state.sync ?? false,
    serializer: StoreSyncSerializer = DefaultStoreSyncSerializer
  ) {
    if (process.client && !!localStorage) {
      const key = `${store.$id}-sync`

      store.$subscribe((mutation, state) => {
        if (mutation.type === MutationType.direct && filter(state)) {
          // console.log('SUBSCRIBE', store.$id, selfId, state)
          localStorage.setItem(key, serializer.serialize({ source: selfId, state: state }))
        }
      })

      window.addEventListener('storage', (ev) => {
        if (ev.key !== key) return

        const item = serializer.deserialize(ev.newValue!)

        if (!item || !item.source || item.source === selfId) return

        if (filter(item.state)) {
          // console.log('UPDATE', store.$id, item.source, item.state)
          store.$state = item.state
        }

        const { touch } = CookieJar.use()
        touch()
      })
    }
  }
}
