<template>
  <app-auvious-widget
    style="display: none"
    id="auvious-widget"
    auvious-environment="us.auvious.video"
    active-widgets="cobrowse"
    greeting-sound-on="false"
    greeting-action="cobrowse"
    wait-for-greeting-seconds="-1"
  ></app-auvious-widget>
</template>

<script lang="ts" setup>
  import { useScriptTag } from '@vueuse/core'

  useScriptTag('https://us.auvious.video/widget/dist/auvious/auvious.esm.js', () => {}, {
    type: 'module'
  })

  useScriptTag('https://us.auvious.video/widget/dist/auvious/auvious.js', () => {}, {
    noModule: true
  })

  const widget = ref<any | null>(null)

  onMounted(async () => {
    await customElements.whenDefined('app-auvious-widget')
    widget.value = document.getElementById('auvious-widget')
    widget.value.addEventListener('cobrowseConnecting', () => {
      document.getElementById('cobrowse-start')?.setAttribute('disabled', 'disabled')
    })

    widget.value.addEventListener('cobrowseDisconnected', () => {
      document.getElementById('cobrowse-start')?.removeAttribute('disabled')
      widget.value.setAttribute('style', 'display: none')
    })

    //   const sheet = new CSSStyleSheet()
    //   sheet.replaceSync('.av-floating.av-floating-bottom { bottom: 90px!important }')
    //   if (widget.value?.shadowRoot) {
    //     widget.value.shadowRoot.adoptedStyleSheets = [sheet]
    //   }
  })

  const { $root } = useNuxtApp()

  $root.on('launch-auvious', launch)

  function launch() {
    widget.value.setAttribute('style', 'display: block')
    widget.value.launch('cobrowse')
  }
</script>

<style>
  .av-floating {
    bottom: 200px;
  }
</style>
