import { PlanBenefitCostType, PlanType } from '~/generated/api-clients-generated'

interface IAddress {
  city: string
  state: string
  zip?: string | null
  line1: string
  line2?: string | null
}

export function formatAddress(address: IAddress) {
  return formatStreet(address) + formatCityStateZip(address)
}

export function formatStreet(address: IAddress) {
  return `${address.line1}, ${address.line2 ?? ''}${address.line2 ? ', ' : ''}`
}

export function formatCityStateZip(address: IAddress) {
  return `${address.city}, ${address.state} ${address.zip?.substring(0, 5)}`
}

export function titleCase(str: string | null) {
  return str?.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase()
  })
}

const dollarsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

const dollarsCentsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0
})

export const formatUsd = (amount: number): string => {
  return amount % 1 !== 0 ? dollarsCentsFormatter.format(amount) : dollarsFormatter.format(amount)
}

export const formatPct = (amount: number) => {
  return percentageFormatter.format(amount)
}

export const stripHtml = (raw: string): string => {
  return _replace(raw, /<.+?>/g, '')
}

export function onlyDigits(s: string | null | undefined) {
  const match = s?.match('[\\d.]+')
  return match ? +match[0] : null
}

export function formatAmountData(amount: number) {
  const formatted = formatUsd(amount)
  const splitFormatted = formatted.split('.')

  return {
    dollars: splitFormatted[0].slice(1),
    cents: '.' + (splitFormatted[1] ?? '00')
  }
}

export function formatPlanType(planType: PlanType) {
  switch (planType) {
    case PlanType.MAPD:
      return 'Medicare Advantage'
    case PlanType.GAP:
      return 'Medicare Supplement'
    case PlanType.PDP:
      return 'Prescription Drugs'
  }
}

export function concatValues(
  values: string[],
  format: boolean = false,
  finalDelimiter: string = 'and'
) {
  let text = ''
  const length = values.length
  for (const [i, value] of values.entries()) {
    if (i > 0 && i != length - 1) text += ', '
    if (i > 0 && i == length - 1) text += ` ${finalDelimiter} `
    text += format ? _startCase(value?.toLowerCase()) : value
  }
  return text
}

export function isVowel(c: string) {
  return ['a', 'e', 'i', 'o', 'u'].indexOf(c.toLowerCase()) !== -1
}

export function formatBenefit(
  {
    amount,
    amountType
  }: {
    amount: number
    amountType: PlanBenefitCostType
  },
  withDescription?: boolean
): string {
  const str = amountType === PlanBenefitCostType.Copay ? formatUsd(amount) : formatPct(amount)

  if (!withDescription) {
    return str
  } else {
    return `${str} ${amountType === PlanBenefitCostType.Copay ? 'copay' : 'coinsurance'}`
  }
}
