type ConsoleOutput = 'log' | 'info' | 'warn' | 'debug' | 'error'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.warnHandler = () => {}
  nuxtApp.vueApp.config.errorHandler = (err, instance, info) => {
    console.log(err, instance, info)
  }

  const runtimeConfig = useRuntimeConfig()
  if (!!window) {
    for (const method of runtimeConfig.public.suppressConsoleOutput) {
      window.console[method as ConsoleOutput] = (...args) => undefined
    }
  }
})
