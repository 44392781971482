import WaypointNodeBase from '~/models/journey/WaypointNodeBase'
import type { IProcessingNode, IRedirectNode, WaypointNode } from '~/models/journey/waypoints'
import { WaypointResult, WaypointType } from '~/generated/api-clients-generated'
import type { Path } from '~/types/util'

export default abstract class RedirectNodeBase extends WaypointNodeBase implements IRedirectNode {
  type: WaypointType.Redirect = WaypointType.Redirect

  abstract go(): Promise<boolean>

  getNext(): WaypointNode | Path | null {
    return this._destinations.get(WaypointResult.Next) ?? null
  }
}
