import type {
  DrugCoverage,
  DrugCoverageSummary,
  PharmacyCost
} from '~/generated/api-clients-generated'

export class DrugCoverageVm {
  readonly retailCost: number | null
  readonly mailCost: number | null
  readonly pharmacyCosts: Array<PharmacyCost>
  readonly coverageSummaries: Array<DrugCoverageSummary>

  constructor(raw?: DrugCoverage | null) {
    this.retailCost = raw?.retailCost ?? null
    this.mailCost = raw?.mailCost ?? null
    this.pharmacyCosts = raw?.pharmacyCosts || []
    this.coverageSummaries = raw?.coverageSummaries || []
  }

  get count() {
    return this.coverageSummaries.length
  }

  get coveredCount() {
    return this.coverageSummaries.filter((x) => x.isCovered).length
  }

  get allCovered() {
    return this.count === this.coveredCount
  }

  isCovered(externalId: string) {
    return this.coverageSummaries.find((d) => d.externalId === externalId)!.isCovered
  }

  getTierId(externalId: string) {
    return this.coverageSummaries.find((d) => d.externalId === externalId)!.tier
  }

  getMonthlyAverage(externalId: string) {
    const total = this.pharmacyCosts?.[0].monthlyCosts?.reduce(
      (agg, el) => agg + (el.details?.find((d) => d.externalId === externalId)?.memberCost ?? 0),
      0
    )

    return _isNil(total) ? 0 : total / 12
  }
}
