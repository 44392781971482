import type { LDClientBase, LDContext, LDOptionsBase } from 'launchdarkly-js-sdk-common'
import { ProfileStore } from '~/stores/profile'

export async function registerLaunchDarkly(
  initialize: (envKey: string, context: LDContext, options?: LDOptionsBase) => LDClientBase
) {
  const config = useRuntimeConfig()
  const { isHealthCheck } = AppRoute.use()
  const { deviceId } = CookieJar.use()

  const isRender = process.server

  const deviceKey = isRender ? 'render' : deviceId.value

  const key = isRender ? 'render' : isHealthCheck.value ? 'healthcheck' : deviceKey
  const isAnon = !isHealthCheck.value && !isRender

  const context: LDContext = {
    kind: 'device',
    key: key,
    anonymous: isAnon
  }

  const { createSiteClient } = PxApi.use()
  const $site = createSiteClient()

  let opts: { bootstrap: any; [key: string]: any } = {
    bootstrap: (await $site.bootstrapFlags(key, isAnon)).state
  }

  if (config.public?.launchDarklyStreamUrl) opts.streamUrl = config.public.launchDarklyStreamUrl
  if (config.public?.launchDarklyBaseUrl) opts.baseUrl = config.public.launchDarklyBaseUrl
  if (config.public?.launchDarklyEventsUrl) opts.eventsUrl = config.public.launchDarklyEventsUrl

  const client = initialize(config.public.launchDarklyKey, context, opts)

  const ldInitialized = ref(false)

  client.on('initialized', () => (ldInitialized.value = true))

  return {
    provide: {
      ld: client,
      ldReady: ldInitialized
    }
  }
}
