import type {
  DrugAutocompleteResult,
  DrugData,
  Rx,
  RxDosage
} from '~/generated/api-clients-generated'
import type { InjectionKey } from '@vue/runtime-core'

export class RxService {
  static #drugDataCache: Record<string, DrugData> = {}

  static use() {
    function createIdentifier(rx: Rx) {
      return `${rx.externalId}-${rx.dosage!.ndc}`
    }

    function parseIdentifier(identifier: string) {
      const split = identifier?.split('-')
      if (split?.length != 2) {
        console.error('invalid identifier: ', identifier)
      }
      return {
        externalId: split[0],
        ndc: split[1]
      }
    }

    async function autocompleteRx(query: string): Promise<DrugAutocompleteResult[]> {
      const cleanQuery = query.trim().toLowerCase()

      const client = PxApi.use().createDrugsClient()
      return await client.autocomplete(cleanQuery, 0, 50)
    }

    async function fetchDrugData(externalId: string) {
      const client = PxApi.use().createDrugsClient()

      if (!RxService.#drugDataCache[externalId]) {
        RxService.#drugDataCache[externalId] = await client.get(externalId)
      }

      return RxService.#drugDataCache[externalId]
    }

    function formatFreq(days: number) {
      return days === 30 ? 'Every month' : `Every ${Math.floor(days / 30)} months`
    }

    function formatDosageForm(dosage: RxDosage | null, quantity: number | null) {
      return !!dosage?.package
        ? `${quantity} ${dosage?.package!.displayText}`
        : `${quantity} ${dosage?.formName}${quantity && quantity > 1 ? 's' : ''}`
    }

    function formatRxSummary(rx: Rx) {
      return `${rx?.dosage?.strength}, ${formatDosageForm(
        rx?.dosage!,
        rx?.quantity!
      )}, ${formatFreq(rx?.frequency!)}`
    }

    const frequencies = [30, 60, 90, 180, 365].map((n) => ({
      days: n,
      desc: formatFreq(n)
    }))

    return {
      createIdentifier,
      parseIdentifier,
      autocompleteRx,
      fetchDrugData,
      formatFreq,
      formatDosageForm,
      formatRxSummary,
      frequencies
    }
  }

  static readonly #injectionKeys = {
    editId: Symbol() as InjectionKey<Ref<string | null>>,
    closeAfterEdit: Symbol() as InjectionKey<Ref<boolean>>
  }

  static provide() {
    const editId = ref<string | null>(null)

    provide(this.#injectionKeys.editId, editId)

    const closeAfterEdit = ref<boolean>(false)

    provide(this.#injectionKeys.closeAfterEdit, closeAfterEdit)

    return {
      editId,
      closeAfterEdit
    }
  }

  static inject = () => ({
    editId: inject(this.#injectionKeys.editId, ref(null)),
    closeAfterEdit: inject(this.#injectionKeys.closeAfterEdit, ref(false))
  })
}
